import styles from "./Slider.module.scss";

import { t } from "~/i18n";

type SliderProps = {
  staticValue: number;
  dynamicValue?: number;
  onChange?: (value: number) => void;
  legendLeft?: string;
  legendRight?: string;
  title: string;
  className: string;
};

const SliderHunger = ({
  staticValue,
  dynamicValue,
  onChange,
  legendLeft,
  legendRight,
  title,
  className
}: SliderProps) => {
  const getHungerText = () => {
    if (staticValue > 0.8) {
      return t("performance.journals.review.stuffed");
    }
    if (staticValue > 0.6) {
      return t("performance.journals.review.full");
    }
    if (staticValue > 0.4) {
      return t("performance.journals.review.neutral");
    }
    if (staticValue > 0.2) {
      return t("performance.journals.review.hungry");
    }

    return t("performance.journals.review.starving");
  };

  return (
    <div
      className={styles.rating}
      style={{ backgroundPositionX: `${staticValue * 100}%` }}
    >
      <div className={styles.hungerSubHeader}>
        <p className={styles.title}>{`${title}: `}</p>
        <p>{getHungerText()}</p>
      </div>
      <div className={styles.rangeContainer}>
        <input
          className={`${className} ${styles.slider}`}
          type="range"
          min="0"
          max="1"
          step="0.001"
          defaultValue={dynamicValue !== undefined ? dynamicValue : staticValue}
          onChange={(event) => {
            onChange
              ? onChange(parseInt(event.currentTarget.value))
              : undefined;
          }}
          disabled={dynamicValue === undefined}
        />
        <input
          className={styles.sliderStaticValue}
          type="range"
          min="0"
          max="1"
          step="0.001"
          defaultValue={staticValue}
          disabled
        />
      </div>
      <div className={styles.legends}>
        <span className={`${styles.text} ${styles.left}`}>
          {legendLeft ?? ""}
        </span>
        <span className={`${styles.text} ${styles.right}`}>
          {legendRight ?? ""}
        </span>
      </div>
    </div>
  );
};

export default SliderHunger;
