import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";

import MonthCalendar from "./calendar/monthCalendar/ MonthCalendar";
import WeekCalendar from "./calendar/weekCalendar/WeekCalendar";
import MedicationEmptyState from "./components/MedicationEmptyState";
import MedicationCardPage from "./medicationCard/MedicationCardPage";
import styles from "./MedicationPage.module.scss";
import { sortUpcomingLogs } from "./utils/MedicationUtility";

import { UserPageRouteContext } from "../userPage/UserPage.context";

import { getMedication, getMedicationLogs } from "~/api/medicationRequests";
import DateRangeNavigator from "~/components/dateRangeNavigator/DateRangeNavigator";
import MonthRangeNavigator from "~/components/monthRangeNavigator/MonthRangeNavigator";
import CustomSelect from "~/components/select/CustomSelect";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import SkeletonMedicationPage from "~/components/skeletons/SkeletonMedicationPage";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import useProgram from "~/hooks/useApi/useProgram";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import type {
  MedicationLogSanitised,
  MedicationOption,
  UserURLParams
} from "~/typing/carePortalTypes";
import { MedicationReminderType } from "~/typing/carePortalTypes";
import type { Medication, Reminder } from "~/typing/sidekickTypes";

const DAYS_TO_DISPLAY_WEEK = 7;
const WEEK_VIEW_SKIP = 7;

const MedicationPage = () => {
  const isMounted = useRef(false);
  const [medication, setMedication] = useState<Medication[]>([]);
  const [medicationForSelect, setMedicationForSelect] = useState<
    MedicationOption[]
  >([]);

  const [logs, setLogs] = useState<MedicationLogSanitised[]>([]); // All logs
  const [logsFiltered, setLogsFiltered] = useState<MedicationLogSanitised[]>(
    []
  ); // Filtered logs, used for the select dropdown
  const [toggleMedicine, setToggleMedicine] = useState(false); // true = medicine overview, false = calendar overview - injection log

  //Used for the week view
  const [currentFirstDay, setCurrentFirstDay] = useState(0); // 0 = today, 7 = next week, -7 = last week
  const [currentFirstDayDate, setCurrentFirstDayDate] = useState(
    DateTime.now()
  );

  const { t } = useTranslation();

  //Used for the toggle switch
  const switchData = [
    {
      label: t("medication.calendar"),
      value: false
    },
    {
      label: t("medication.medicine"),
      value: true
    }
  ];

  //Used for the month view
  const [currentMonth, setCurrentMonth] = useState(DateTime.now().get("month"));
  const [currentMonthDate, setCurrentMonthDate] = useState(
    DateTime.now().startOf("month")
  );
  //Date offset used for the week view
  const [dateOffset, setDateOffset] = useState(0);

  const [selectedMedication, setSelectedMedication] = useState<string>();
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const { program } = useProgram({
    programCatalogItemId: program_id,
    locale
  });

  const [isLoading, setIsLoading] = useState(true);

  const [selectedCalendar, setSelectedCalendar] = useState<string>("week");

  const {
    userDetail,
    setShowMessages: onShowMessages
  } = useOutletContext<UserPageRouteContext>();

  //Amplitude tracking hooks
  const {
    trackCalendarViewOpened,
    trackMedicationViewOpened,
    trackMedicationFilterSelected,
    trackMedicationReminderSent
  } = useAmplitudeTracking();

  /**
   * Get an array of medication options for the select component.
   *
   * @param {Medication[]} medication - An array of medication objects.
   * @returns {MedicationOption[]} - An array of medication options with value and title properties.
   */
  const getMedicationOptions = (
    medication: Medication[]
  ): MedicationOption[] => {
    // Map the medication array to create an array of medication options
    const medicationOptions: MedicationOption[] =
      medication?.map((med) => ({
        value: med.id,
        title: med.name
      })) || [];

    // Sort the medication options alphabetically by title
    medicationOptions.sort((a, b) => a.title.localeCompare(b.title));

    // Add the "All Medicines" option at the beginning of the array
    medicationOptions.unshift({
      value: "-1",
      title: t("medication.allMedicines")
    });

    return medicationOptions;
  };

  /**
   * Process interval-based reminders and generate upcoming logs.
   * @param reminder The reminder object
   * @param medication The medication object
   * @returns An array of upcoming logs
   */
  const processIntervalReminder = (
    reminder: Reminder,
    medication: Medication
  ): MedicationLogSanitised[] => {
    let upcomingLogs: MedicationLogSanitised[] = [];
    // Return an empty array if the period is not equal to 1
    if (reminder.periodically.period !== 1) {
      return upcomingLogs;
    }

    const startDate = DateTime.fromISO(reminder.periodically.startDate);
    const currentTime = DateTime.now();

    // Calculate the number of days since the start date
    const daysSinceStart = Math.floor(currentTime.diff(startDate, "days").days);

    // Find the next reminder day
    const nextReminderDay =
      Math.ceil(
        daysSinceStart /
          (reminder.periodically.every * reminder.periodically.period)
      ) *
      (reminder.periodically.every * reminder.periodically.period);

    // Get the start of the current month and end of the next month
    const startOfMonth = currentTime.startOf("month");
    const endOfMonth = currentTime.plus({ months: 1 }).endOf("month");

    // Iterate through the days in the current month
    for (let i = 0; i <= endOfMonth.diff(startOfMonth, "days").days; i++) {
      let currentDay = DateTime.now();
      if (reminder.periodically.every === 1) {
        currentDay = startDate.plus({
          days:
            nextReminderDay +
            i * (reminder.periodically.every * reminder.periodically.period)
        });
      } else {
        currentDay = startDate.plus({
          days: nextReminderDay + reminder.periodically.every * i
        });
      }
      // Check if the current day is in the current month
      if (currentDay >= startOfMonth && currentDay <= endOfMonth) {
        // Iterate over each time in the reminder
        reminder.periodically.time.forEach((time) => {
          if (
            time <
              (DateTime.now().setZone(userDetail?.timezoneId).toISOTime() ??
                0) &&
            currentDay.day < DateTime.now().day &&
            currentDay.month <= DateTime.now().month
          ) {
            return;
          }

          // Create a new log object for each scheduled time
          const newLog: MedicationLogSanitised = {
            id: medication.id,
            medicationName: medication.name,
            scheduledDate: currentDay.toISO() ?? "",
            icon: medication.iconId,
            iconColor: medication.iconColor,
            status: "USERMEDICATION_UPCOMING",
            time: time,
            upcoming: true
          };

          // Add the new log object to the upcoming logs array
          upcomingLogs.push(newLog);
        });
      }
    }

    upcomingLogs = sortUpcomingLogs(upcomingLogs);

    return upcomingLogs;
  };

  /**
   * Process weekday-based reminders and generate upcoming logs for the current month.
   * @param reminder The reminder object
   * @param medication The medication object
   * @returns An array of upcoming logs
   */
  const processWeekdayReminder = (
    reminder: Reminder,
    medication: Medication
  ): MedicationLogSanitised[] => {
    let upcomingLogs: MedicationLogSanitised[] = [];
    const currentMonth = DateTime.now().startOf("month");
    const firstWeekday = DateTime.now().startOf("week");

    const firstDay = DateTime.now().startOf("month");
    const lastDay = DateTime.now().endOf("month");
    const daysInMonth = lastDay.diff(firstDay, "days").days + 1;
    const weeksInMonth = Math.ceil(daysInMonth / 7);

    const currentMonthWeeks: number[] = [];

    for (let i = 0; i < weeksInMonth + 1; i++) {
      currentMonthWeeks.push(
        currentMonth.plus({ weeks: i + 1 }).weekNumber as number
      );
    }

    // Iterate over each week in the current month
    currentMonthWeeks.forEach((week, index) => {
      // Iterate over each day in the weekday schedule
      reminder.weekdaySchedule.weekDays.forEach((day) => {
        // Skip days that have already passed in the current week
        if (DateTime.now().weekday > day && week <= DateTime.now().weekNumber) {
          return;
        }

        // Iterate over each time in the weekday schedule
        reminder.weekdaySchedule.time.forEach((time) => {
          if (
            time <
              (DateTime.now().setZone(userDetail?.timezoneId).toISOTime() ??
                0) &&
            day === DateTime.now().weekday &&
            week <= DateTime.now().weekNumber
          ) {
            return;
          }

          // Create a new log object for each scheduled time
          const newLog: MedicationLogSanitised = {
            id: medication.id,
            medicationName: medication.name,
            scheduledDate:
              firstWeekday.plus({ days: day - 1, weeks: index }).toISO() ?? "",
            icon: medication.iconId,
            iconColor: medication.iconColor,
            status: "USERMEDICATION_UPCOMING",
            time: time,
            upcoming: true
          };

          // Add the new log object to the upcoming logs array
          upcomingLogs.push(newLog);
        });
      });
    });

    upcomingLogs = sortUpcomingLogs(upcomingLogs);

    return upcomingLogs;
  };
  /**
   * Calculate the upcoming logs based on the medication reminders.
   * @param medications The medications the user has
   * @returns An array of upcoming logs
   */
  const getUpcomingLogs = (
    medications: Medication[]
  ): MedicationLogSanitised[] => {
    const upcomingLogs: MedicationLogSanitised[] = [];

    // Iterate over each medication
    medications.forEach((medication) => {
      //If there are no reminders then skip this medication
      if (!medication.reminders) return;

      // Iterate over each reminder for the medication
      medication.reminders.forEach((reminder) => {
        // Check if the reminder has a weekday schedule
        if (reminder.weekdaySchedule) {
          // Process the weekday-based reminder and add the logs to the upcoming logs array
          const weekdayLogs = processWeekdayReminder(reminder, medication);
          upcomingLogs.push(...weekdayLogs);
        } else {
          // Process the interval-based reminder and add the logs to the upcoming logs array
          const intervalLogs = processIntervalReminder(reminder, medication);
          upcomingLogs.push(...intervalLogs);
        }
      });
    });

    return upcomingLogs;
  };

  useEffect(() => {
    if (!program) return;
    trackMedicationViewOpened();
  }, [program]);

  useEffect(() => {
    // Track component mount status to prevent memory leaks in async functions
    isMounted.current = true;

    //Calculate the offset between the start of the week and today. If it's Tuesday the offset will be -1.
    const today = DateTime.now();
    const startOfWeek = DateTime.now().startOf("week");
    const diff = startOfWeek.diff(today, "days")?.days;
    setDateOffset(Math.trunc(diff));
    // Fetch medication and medication logs data
    const fetchData = async (): Promise<void> => {
      setIsLoading(true);
      const medsData = await getMedication({ userId: user_id });
      const logsData = await getMedicationLogs({ userId: user_id });

      // Filter logs to only show logs with entries
      const filteredLogs = logsData?.filter((log) => log?.logs?.length > 0);

      // Sanitize logs data for use in cards
      let sanitizedLogs: MedicationLogSanitised[] =
        filteredLogs?.flatMap((log) =>
          log.logs.map((item) => ({
            medicationName: log.medication.name,
            icon: log.medication.iconId,
            iconColor: log.medication.iconColor,
            scheduledDate: item.scheduledDate,
            completedDate: item.completedDate,
            status: item.status.name,
            id: log.medication.id,
            reminders: log?.medication?.reminders,
            upcoming: false,
            time: item.scheduledDate?.split("T")[1].slice(0, 5)
          }))
        ) || [];

      sanitizedLogs = sortUpcomingLogs(sanitizedLogs);

      const logsForToday = sanitizedLogs.filter((log) => {
        return (
          DateTime.fromISO(log.scheduledDate).startOf("day").toMillis() ===
          DateTime.now().startOf("day").toMillis()
        );
      });

      //Add upcoming logs to the sanitized logs
      const upcomingLogs = getUpcomingLogs(medsData);

      //If upcoming logs include logs for today with the same time as the logs for today, remove the upcoming log
      upcomingLogs.forEach((upcomingLog) => {
        logsForToday.forEach((logForToday) => {
          if (
            upcomingLog.time === logForToday.time &&
            upcomingLog.id === logForToday.id &&
            DateTime.fromISO(upcomingLog.scheduledDate)
              .startOf("day")
              .toMillis() === DateTime.now().startOf("day").toMillis()
          ) {
            upcomingLog.upcoming = false;
            //remove the upcoming log from the array
            const index = upcomingLogs.indexOf(upcomingLog);
            if (index > -1) {
              upcomingLogs.splice(index, 1);
            }
          }
        });
      });

      sanitizedLogs.push(...upcomingLogs);

      // Filter logs based on selectedMedication (if set)
      const logsToSet = selectedMedication
        ? sanitizedLogs.filter((log) => log.id === selectedMedication)
        : sanitizedLogs;

      // Update state if the component is still mounted
      if (isMounted.current) {
        setLogsFiltered(logsToSet);
        setLogs(logsToSet);
        setMedication(medsData);
        setIsLoading(false);
      }
    };

    // Call fetchData and handle errors
    fetchData().catch(() => {
      displayErrorToast({ message: t("medication.error") });
    });

    // Add a cleanup function to set isMounted.current to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // If selectedMedication is set, filter the logs to only show the selected medicine
    if (selectedMedication) {
      const filteredSanitizedLogs = logs?.filter((log) => {
        return log.id === selectedMedication;
      });

      setLogsFiltered(filteredSanitizedLogs);
    } else {
      setLogsFiltered(logs);
    }
  }, [selectedMedication]);

  useEffect(() => {
    setMedicationForSelect(getMedicationOptions(medication));
  }, [medication]);

  useEffect(() => {
    setCurrentFirstDayDate(
      DateTime.now().plus({ days: dateOffset + currentFirstDay })
    );
  }, [currentFirstDay, dateOffset, toggleMedicine]);

  useEffect(() => {
    setCurrentMonthDate(
      DateTime.now().startOf("month").set({ month: currentMonth })
    );
  }, [currentMonth, toggleMedicine]);

  //Used for calendar selection
  const calendarOptionForSelect: MedicationOption[] = [
    { value: "week", title: t("time.week") },
    { value: "month", title: t("time.month") }
  ];

  return (
    <SentryErrorBoundary transactionName="MedicationPage">
      <div>
        <div className={styles.topSettings}>
          {toggleMedicine === false ? (
            <div className={styles.topSettingsPeriod}>
              <div>
                <label className="label label--block">
                  {t("medication.period")}
                </label>
                <CustomSelect
                  options={calendarOptionForSelect}
                  renderOption={(option) => option.title}
                  renderSelectedOption={(option) => option.title}
                  className={styles.topSettingsPeriodSelect}
                  placeholder="Calendar type selection"
                  onChange={(value) => {
                    if (value.target.value === "-1") setSelectedCalendar("");
                    else setSelectedCalendar(value.target.value);
                  }}
                  value={selectedCalendar}
                />
              </div>
              <div>
                <label className="label label--block">
                  {t("medication.medicine")}
                </label>
                <CustomSelect
                  options={medicationForSelect}
                  renderOption={(option) => option.title}
                  renderSelectedOption={(option) => option.title}
                  className={styles.topSettingsPeriodSelect}
                  placeholder={t("medication.allMedicines")}
                  onChange={({ target: { value } }) => {
                    if (value === "-1") {
                      setSelectedMedication(undefined);
                    } else {
                      setSelectedMedication(value);
                      trackMedicationFilterSelected(value);
                    }
                  }}
                  value={selectedMedication}
                />
              </div>
            </div>
          ) : null}
          {toggleMedicine === false ? (
            <div className={styles.topSettingsWeekPicker}>
              {selectedCalendar === "week" ? (
                <DateRangeNavigator
                  startDate={
                    DateTime.fromISO(
                      DateTime.now().startOf("week").toISO() ?? ""
                    )
                      .plus({ days: currentFirstDay })
                      .toISO() ?? ""
                  }
                  endDate={
                    DateTime.fromISO(
                      DateTime.now().startOf("week").toISO() ?? ""
                    )
                      .plus({
                        days: currentFirstDay + DAYS_TO_DISPLAY_WEEK - 1
                      })
                      .toISO() ?? ""
                  }
                  increaseDate={() => {
                    if (currentFirstDay < 0) {
                      setCurrentFirstDay((prev) => prev + WEEK_VIEW_SKIP);
                    }
                  }}
                  decreaseDate={() =>
                    setCurrentFirstDay((prev) => prev - WEEK_VIEW_SKIP)
                  }
                  onTodayClick={() => setCurrentFirstDay(0)}
                  nextButtonDisabled={currentFirstDay >= 0}
                />
              ) : (
                <MonthRangeNavigator
                  startDate={
                    DateTime.fromISO(
                      //Get the start of the current year
                      DateTime.now().startOf("year").toISO() ?? ""
                    )
                      .plus({ months: currentMonth - 1 })
                      .toISO() ?? ""
                  }
                  increaseMonth={() => {
                    if (DateTime.now().get("month") !== currentMonth)
                      setCurrentMonth((prev) => prev + 1);
                  }}
                  decreaseMonth={() => setCurrentMonth((prev) => prev - 1)}
                  onThisMonthClick={() =>
                    setCurrentMonth(DateTime.now().get("month"))
                  }
                  nextButtonDisabled={
                    DateTime.now().get("month") === currentMonth
                  }
                />
              )}
            </div>
          ) : null}
          <ToggleSwitch
            switchData={switchData}
            setValue={() => {
              if (toggleMedicine === true) {
                trackCalendarViewOpened();
              } else {
                trackMedicationViewOpened();
              }
              setToggleMedicine(!toggleMedicine);
            }}
            currentValue={toggleMedicine}
            className={styles.topSettingsSwitch}
          />
        </div>
        {!isLoading && toggleMedicine === true ? (
          medication.length > 0 ? (
            <MedicationCardPage
              medication={medication}
              setToggleMedicine={setToggleMedicine}
              setSelectedMedication={setSelectedMedication}
            />
          ) : (
            <MedicationEmptyState
              onShowMessages={onShowMessages}
              trackMedicationReminderSent={trackMedicationReminderSent}
              type={MedicationReminderType.MedicationCard}
            />
          )
        ) : !isLoading ? (
          logs?.length > 0 ? (
            selectedCalendar === "week" ? (
              <WeekCalendar
                startDate={currentFirstDayDate}
                weekOffset={currentFirstDay}
                logs={logsFiltered}
              />
            ) : (
              <MonthCalendar month={currentMonthDate} logs={logsFiltered} />
            )
          ) : (
            <MedicationEmptyState
              onShowMessages={onShowMessages}
              trackMedicationReminderSent={trackMedicationReminderSent}
              type={MedicationReminderType.MedicationLog}
            />
          )
        ) : undefined}
        {isLoading && <SkeletonMedicationPage />}
      </div>
    </SentryErrorBoundary>
  );
};

export default MedicationPage;
