import api from "~/api/api";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

export const removePinnedNote = async (
  userId: string,
  pinnedNoteId: string
): Promise<any> => {
  try {
    const path = `/coach/users/${userId}/journalPins/${pinnedNoteId}`;
    const res = await api.delete(path);
    if (res?.res?.ok) {
      displaySuccessToast({ message: t("notes.unPinSuccess") });
    } else {
      displayErrorToast({ message: t("notes.unPinError") });
    }
  } catch (err) {
    displayErrorToast({ message: t("notes.unPinError") });
  }
};

export const addPinnedNote = async (
  programId: string,
  userId: string,
  noteId: string
): Promise<any> => {
  try {
    const path = `/coach/programs/${programId}/users/${userId}/journalitems/${noteId}/journalPins`;
    const res = await api.post(path, {});

    if (res?.res?.ok) {
      displaySuccessToast({ message: t("notes.pinSuccess") });
    } else {
      displayErrorToast({ message: t("notes.pinError") });
    }
  } catch (err) {
    displayErrorToast({ message: t("notes.pinError") });
  }
};
