import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./CoachRecommendedMessageModal.module.scss";

import AutoresizeTextarea from "../autoresizeTextarea/AutoresizeTextarea";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import PulseLoader from "../pulseLoader/PulseLoader";

import ArrowIcon from "~/assets/arrow-right-thick.svg";
import EditIcon from "~/assets/edit.svg";
import SidekickShrugging from "~/assets/sidekick-shrugging.png";
import { useCoachSuggestedMessages } from "~/hooks/useApi/useCoachSuggestedMessages";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { MessageSuggestion } from "~/typing/sidekickTypes";

type CoachRecommendedMessageModalProps = {
  userId: string;
  programId: string;
  locale: string;
  setShowRecommendedReplies?: (show: boolean) => void;
  onSubmitMessage: (message: {
    message: string;
    messageSuggestionId?: string;
  }) => void;
  submitButtonText?: string;
  trackOnOpen?: () => void;
};

const CoachRecommendedMessageModal = ({
  userId,
  programId,
  locale,
  setShowRecommendedReplies,
  onSubmitMessage,
  submitButtonText,
  trackOnOpen
}: CoachRecommendedMessageModalProps) => {
  const { messages, isLoading, isError } = useCoachSuggestedMessages({
    programId,
    locale,
    userId
  });

  const [editedMessage, setEditedMessage] = useState<string | undefined>(
    undefined
  );
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { t } = useTranslation();
  const {
    trackRecommendedMessageEdited,
    trackRecommendedMessageEditDoneSelected,
    trackRecommendedMessageEditModeCancelled,
    trackRecommendedMessageTraversed,
    trackRecommendedMessagesExited
  } = useAmplitudeTracking();

  const [
    chosenRecommendedMessage,
    setChosenRecommendedMessage
  ] = useState<MessageSuggestion>();

  useEffect(() => {
    if (messages && messages.length > 0) {
      setChosenRecommendedMessage(messages[0]);
    }
  }, [messages]);

  useEffect(() => {
    trackOnOpen?.();
  }, []);

  const traverseChosenMessageIndex = (change: number) => {
    setEditedMessage(undefined);
    setEditEnabled(false);
    const index =
      change +
      messages.findIndex(
        (message) =>
          message.suggestion_id === chosenRecommendedMessage?.suggestion_id
      );

    let newChosenMessage = messages[index];
    if (index < 0) {
      newChosenMessage = messages[messages.length - 1];
    } else if (index >= messages.length) {
      newChosenMessage = messages[0];
    } else {
      newChosenMessage = messages[index];
    }
    trackRecommendedMessageTraversed({
      messageSuggestionId: newChosenMessage?.suggestion_id ?? "",
      direction: change > 0 ? "next" : "previous"
    });

    return newChosenMessage;
  };

  const handleSendMessage = () => {
    onSubmitMessage({
      message: editedMessage || chosenRecommendedMessage?.message || "",
      messageSuggestionId: chosenRecommendedMessage?.suggestion_id
    });
    setShowRecommendedReplies && setShowRecommendedReplies(false);
  };

  const handleEditClick = () => {
    if (editEnabled) {
      trackRecommendedMessageEditDoneSelected({
        messageSuggestionId: chosenRecommendedMessage?.suggestion_id ?? ""
      });
    } else {
      trackRecommendedMessageEdited({
        messageSuggestionId: chosenRecommendedMessage?.suggestion_id ?? "",
        wordCount: chosenRecommendedMessage?.message?.split(" ").length ?? 0
      });
    }

    setEditEnabled(!editEnabled);
  };

  const handleEditCancel = () => {
    setEditEnabled(false);
    setEditedMessage(undefined);
    trackRecommendedMessageEditModeCancelled({
      messageSuggestionId: chosenRecommendedMessage?.suggestion_id ?? ""
    });
  };

  useEffect(() => {
    if (editEnabled === true) {
      textAreaRef.current?.focus();
      const value = textAreaRef.current?.value;
      if (value) {
        textAreaRef.current?.setSelectionRange(value.length, value.length);
      }
    }
  }, [editEnabled]);

  return (
    <Modal
      className={styles.modal}
      onClose={() => {
        trackRecommendedMessagesExited();
        setShowRecommendedReplies && setShowRecommendedReplies(false);
      }}
      title={t("messages.messagesWindow.recommendedMessages")}
    >
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <p>{t("messages.ai.recommended.explanation")}</p>
        </div>
        {isLoading && <PulseLoader inverted className={styles.loader} />}
        {((!isLoading && messages?.length === 0) || isError) && (
          <div className={styles.noMessages}>
            <img src={SidekickShrugging} alt="" />
            <p>{t("messages.ai.recommended.noMessages")}</p>
          </div>
        )}
        {!isLoading && messages.length > 0 && (
          <>
            <div className={styles.editActions}>
              {editEnabled && (
                <button
                  className={styles.editButton}
                  onClick={handleEditCancel}
                  disabled={editedMessage === ""}
                >
                  {t("general.reset")}
                </button>
              )}
              <button
                className={styles.editButton}
                onClick={handleEditClick}
                disabled={editedMessage === ""}
              >
                {editEnabled ? (
                  t("messages.preview")
                ) : (
                  <>
                    <img src={EditIcon} alt="" /> {t("messages.edit")}
                  </>
                )}
              </button>
            </div>
            {editEnabled ? (
              <AutoresizeTextarea
                className={styles.messageEdit}
                value={
                  editedMessage !== undefined
                    ? editedMessage
                    : chosenRecommendedMessage?.message || ""
                }
                onChange={(value) => setEditedMessage(value)}
                placeholder={""}
                ref={textAreaRef}
              />
            ) : (
              <div className={styles.messagePreview}>
                {editedMessage || chosenRecommendedMessage?.message || ""}
              </div>
            )}

            <div className={styles.actions}>
              <div className={styles.toggles}>
                <button
                  className={styles.toggle}
                  onClick={() =>
                    setChosenRecommendedMessage(() =>
                      traverseChosenMessageIndex(-1)
                    )
                  }
                >
                  <img src={ArrowIcon} />
                </button>{" "}
                <p>
                  {messages.findIndex(
                    (message) =>
                      message.suggestion_id ===
                      chosenRecommendedMessage?.suggestion_id
                  ) + 1}{" "}
                  / {messages.length}
                </p>
                <button
                  className={styles.toggle}
                  onClick={() =>
                    setChosenRecommendedMessage(() =>
                      traverseChosenMessageIndex(1)
                    )
                  }
                >
                  <img src={ArrowIcon} />
                </button>
              </div>
              <div className={styles.actionButtons}>
                <Button
                  onClick={() => {
                    handleSendMessage();
                  }}
                  disabled={editedMessage === ""}
                >
                  {submitButtonText ?? t("messages.send")}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CoachRecommendedMessageModal;
