import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { SegmentationKey } from "~/constants/segmentationKeys";

type Props = {
  featureName: SegmentationKey;
  userId?: string;
};

export const useFeatureToggle = ({ featureName, userId }: Props) => {
  const path = `/coach/features/${featureName}/enabled?${
    userId ? "userId=" + userId : ""
  }`;

  const { data, isLoading, invalidate } = useApi<{ enabled: boolean }>({
    queryKey: QueryKeyFactory.segmentation(featureName, userId),
    path,
    refetchOnWindowFocus: false,
    shouldRetry: false
  });

  return {
    isEnabled: data?.enabled,
    isLoading,
    invalidate
  };
};
