import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { createRef, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { Link, useLocation, useParams } from "react-router-dom";

import ExternalUserChangeLog from "./ExternalUserChangeLog";
import styles from "./RegisterPatient.module.scss";

import {
  getCallConsentTypes,
  getHipaaCertifiedTypes,
  getCallRecordingDisclaimerTypes,
  getUnderlyingRiskTypes,
  getCountryByCountryCode,
  getOutreachTypeOptions,
  getEnrolmentFilterTypes,
  getRelationshipText,
  getRetriggerOptions
} from "../helper";

import api from "~/api/api";
import { getExternalUserHistory } from "~/api/requests/externalUserRequests";
import CustomPhoneInput from "~/components/customPhoneInput/CustomPhoneInput";
import DateInput from "~/components/input/DateInput";
import Input from "~/components/input/Input";
import Modal from "~/components/modal/Modal";
import CustomCheckBoxSelect from "~/components/select/CustomCheckBoxSelect";
import CustomSelect from "~/components/select/CustomSelect";
import config from "~/config";
import { EnrolmentStatuses } from "~/constants/enrolmentStatuses";
import getDiseaseNameOptions from "~/constants/getDiseaseNameOptions";
import { HipaaStatus } from "~/constants/hipaaStatus";
import { linesOfBusiness } from "~/constants/linesOfBusiness";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import states from "~/constants/states";
import { dateIsValid, getBasicFormattedDate } from "~/helpers/date/dateHelpers";
import getExternalLinkOptions from "~/helpers/getExternalLinkOptions";
import {
  isCancerProgramByUserType,
  isMedicareProgram,
  programHasBehaviour
} from "~/helpers/program/programHelpers";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import {
  getRetriggeredText,
  getRetriggeredValue,
  hasStatusDurationExpired
} from "~/helpers/user/userHelpers";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import useProgram from "~/hooks/useApi/useProgram";
import useUserGroups from "~/hooks/useApi/useUserGroups";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { Location } from "~/typing/carePortalTypes";
import {
  ExternalUser,
  ExternalUserHistory,
  ExternalUserType
} from "~/typing/sidekickTypes";

type SMSSettings = {
  userOptedOut: boolean;
  openSMSModal: () => void;
};

interface RegisterPatientProps {
  onClose: () => void;
  externalUserTypes: ExternalUserType[];
  enrolmentExternalUserTypes?: ExternalUserType[];
  location?: Location;
  externaluser?: ExternalUser;
  smsSettings?: SMSSettings;
  externalUserTypeId?: string;
  onExternalUserUpdate?: (data: ExternalUser) => void;
}

enum InputsThatNeedValidation {
  BirthDate = "BirthDate",
  Identifier = "Identifier",
  Phonenumber = "Phonenumber"
}

const RegisterPatient = ({
  externaluser,
  externalUserTypeId,
  onClose,
  externalUserTypes,
  enrolmentExternalUserTypes,
  location,
  smsSettings,
  onExternalUserUpdate
}: RegisterPatientProps) => {
  const { groups } = useUserGroups({ skipTerminatedClients: true });

  const { t } = useTranslation();

  const phoneNumberInput = useRef<HTMLInputElement>(null);
  const identifierInput = createRef<HTMLInputElement>();
  const { pathname } = useLocation();
  const { trackInviteSMSSent } = useAmplitudeTracking();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { program_id = "", locale = "" } = useParams<{
    program_id: string;
    locale: string;
  }>();
  const { program } = useProgram({ programCatalogItemId: program_id, locale });

  const externalUserType = externalUserTypes.find(
    (type) => type.id === externalUserTypeId
  );
  const queryClient = useQueryClient();

  const [selectedExternalUserType, setSelectedExternalUserType] = useState(
    externalUserType
      ? externalUserType
      : enrolmentExternalUserTypes && enrolmentExternalUserTypes.length > 0
      ? enrolmentExternalUserTypes[0]
      : null
  );
  const [inputErrors, setInputErrors] = useState<
    Map<InputsThatNeedValidation, string>
  >(new Map());

  const [selectedExternalUserTypeId, setSelectedExternalUserTypeId] = useState(
    externalUserType
      ? externalUserType
      : enrolmentExternalUserTypes && enrolmentExternalUserTypes.length > 0
      ? enrolmentExternalUserTypes[0].id
      : null
  );

  const [externalUserHistory, setExternalUserHistory] = useState<
    ExternalUserHistory | undefined
  >();

  const [externalUser, setExternalUser] = useState<ExternalUser | undefined>(
    externaluser
  );
  const [enrolmentStatus, setEnrolmentStatus] = useState(
    externaluser && externaluser.enrolmentStatus
      ? externaluser.enrolmentStatus
      : externaluser
      ? 0
      : config.isAnthem
      ? 1
      : 0
  );

  const [selectedConditions, setSelectedConditions] = useState<string>(
    externalUser?.conditions ?? ""
  );

  const [shouldAllowRetriggerUpdate] = useState(
    externalUser?.eligibleForRestart &&
      externalUser?.eligibleForRestartDate &&
      !hasStatusDurationExpired(
        externalUser?.eligibleForRestartDate,
        program?.restartedStatusDurationSeconds
      )
  );

  const handleSetExternalUserType = (e) => {
    setSelectedExternalUserTypeId(e.target.value);
    externalUserTypes.find((type) => {
      if (type.id === e.target.value) {
        setSelectedExternalUserType(type);
      }
    });
  };

  const updateExternalUserProperty = (propertyName: string, value: any) => {
    setExternalUser((prev) => {
      if (!prev) prev = {} as ExternalUser;
      prev[propertyName] = value;
      return { ...prev };
    });
  };

  const updateExternalUserGroup = (groupNumber: string) => {
    updateExternalUserProperty("groupNumber", groupNumber);
    const groupName = groups?.find((group) => group.number === groupNumber)
      ?.name;

    updateExternalUserProperty("groupName", groupName);
  };

  const updateExternalUserGroupNumber = (groupName: string) => {
    updateExternalUserProperty("groupName", groupName);
    const groupNumber = groups?.find((group) => group.name === groupName)
      ?.number;
    updateExternalUserProperty("groupNumber", groupNumber);
  };

  useEffect(() => {
    const getHistory = async () => {
      let history: ExternalUserHistory | undefined = undefined;
      if (externaluser && selectedExternalUserType) {
        history = await getExternalUserHistory(
          selectedExternalUserType.id,
          externaluser.id
        );
      }
      setExternalUserHistory(history);
    };
    getHistory();
  }, []);

  useEffect(() => {
    updateExternalUserProperty("conditions", selectedConditions);
  }, [selectedConditions]);

  const validatePhoneNumber = () => {
    return (
      !externalUser?.phoneNumber ||
      isPossiblePhoneNumber(externalUser?.phoneNumber)
    );
  };

  const setInputError = (key: InputsThatNeedValidation, error: string) => {
    inputErrors.set(key, error);
    setInputErrors(new Map(inputErrors));
  };

  const save = async () => {
    setError("");
    toast.remove();

    if (!externalUser?.identifier) {
      setInputError(
        InputsThatNeedValidation.Identifier,
        config.isAnthem
          ? `HCID ${t("registerPatient.isRequired")}`
          : `${t("registerPatient.patientId")} ${t(
              "registerPatient.isRequired"
            )}`
      );
      identifierInput.current?.focus();

      return;
    }

    if (!validatePhoneNumber()) {
      setInputError(
        InputsThatNeedValidation.Phonenumber,
        t("registerPatient.phoneNumberValidationGeneric")
      );
      phoneNumberInput.current?.focus();

      return;
    }

    if (config.isAnthem && !dateIsValid(externalUser?.birthDate)) {
      setInputError(
        InputsThatNeedValidation.BirthDate,
        t("registerPatient.birthdateValidationGeneric", "Incorrect date")
      );

      return;
    }

    setLoading(true);
    setError("");

    const registration = { ...externalUser };
    registration.enrolmentStatus = enrolmentStatus;

    let path = `/coach/programs/${program_id}/locales/${locale}/externalusertypes/${selectedExternalUserType?.id}/externalusers`;
    let response;

    if (externaluser) {
      path = `${path}/${externaluser.id}`;
      response = await api.put(path, registration);
    } else {
      response = await api.post(path, registration, true);
    }

    if (response?.res?.ok) {
      if (externaluser && onExternalUserUpdate) {
        onExternalUserUpdate(response?.data);
      } else {
        // If externaluser is undefined, that means we are creating a new one and sending a sms invite. Track that.
        trackInviteSMSSent();
      }
      // Invalidate the user programs so that the user's coach list is updated
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.programs.users(program_id, locale)
      });
      displaySuccessToast({
        message: externaluser
          ? t("registerPatient.saveSuccess")
          : t("registerPatient.registerSuccess")
      });
      setLoading(false);
      return true;
    } else {
      setLoading(false);

      if (response?.res?.status === 409) {
        if (response.body?.error?.message.includes("Identifier")) {
          if (config.isAnthem) {
            setError(t("registerPatient.errorMemberIdAlreadyExists"));
          } else {
            setError(t("registerPatient.errorPatientIdAlreadyExists"));
          }
        } else if (response.body?.error?.message.includes("Phone number")) {
          setError(t("registerPatient.errorPhoneNumberExists"));
        } else {
          setError(t("registerPatient.error"));
        }
      } else {
        setError(
          t("registerPatient.errorSavingRegistration") +
            response?.body?.error?.message
        );
      }

      return false;
    }
  };

  const handleSMSClick = async () => {
    // Only save if phone number edited, else open sms modal without saving.
    // Don't need to call close(), parent component handles close of self in openSMSModal
    if (smsSettings && externaluser) {
      const phoneNumberEdited =
        externaluser?.phoneNumber !== externalUser?.phoneNumber;

      if (phoneNumberEdited) {
        if (await save()) {
          smsSettings.openSMSModal();
        }
      } else {
        smsSettings.openSMSModal();
      }
    }
  };

  const saveAndClose = async () => {
    const success = await save();
    if (success) {
      onClose();
    }
  };

  const phoneNumberIsNotValid =
    !externalUser?.phoneNumber ||
    !isPossiblePhoneNumber(externalUser?.phoneNumber);

  const handlePhoneInputBlur = () => {
    if ((externalUser?.phoneNumber?.length ?? 0) > 0 && phoneNumberIsNotValid) {
      setInputError(
        InputsThatNeedValidation.Phonenumber,
        t("registerPatient.phoneNumberValidationGeneric")
      );
    }
  };

  const getDefaultCountry = () => {
    const country = selectedExternalUserType?.defaultPhoneCountryCode
      ? getCountryByCountryCode(
          selectedExternalUserType.defaultPhoneCountryCode,
          location?.countryCode
        )
      : undefined;
    return country?.code ?? "";
  };

  const modalClasses = config.isAnthem
    ? styles.RegisterPatientBig
    : externaluser
    ? styles.RegisterPatientSmall
    : styles.RegisterPatientSmaller;

  const filteredExternalLinkOptions = getExternalLinkOptions(externaluser);

  const isVariableSelectionMissingForInvite =
    program?.variableSelectionRequiredOnInvite &&
    selectedConditions.length === 0 &&
    !externalUser?.id;

  const canSendSMS = () => {
    return (
      !loading &&
      externalUser?.phoneNumber &&
      !inputErrors.get(InputsThatNeedValidation.Phonenumber) &&
      !smsSettings?.userOptedOut &&
      externalUser?.identifier &&
      selectedExternalUserType &&
      !isVariableSelectionMissingForInvite
    );
  };

  const getLastRetriggerDateText = () => {
    if (externalUser?.eligibleForRestartDate) {
      return getBasicFormattedDate(externalUser?.eligibleForRestartDate);
    } else {
      return "";
    }
  };

  return (
    <>
      <Modal
        title={
          externaluser
            ? t("registerPatient.patientInformation")
            : t("registerPatient.registerPatient")
        }
        className={modalClasses}
        onClose={onClose}
        contentClass={styles.content}
      >
        <div>
          <div className={styles.wrapper}>
            {config.isAnthem && (
              <Input
                label={t("registerPatient.name")}
                dataTestId="name"
                initialValue={externalUser?.name}
                onChange={(value) => updateExternalUserProperty("name", value)}
              />
            )}

            {config.isAnthem && (
              <DateInput
                initialDate={
                  externalUser?.birthDate
                    ? DateTime.fromISO(externalUser?.birthDate ?? "", {
                        zone: "utc"
                      })
                    : undefined
                }
                onChange={(value) =>
                  updateExternalUserProperty(
                    "birthDate",
                    value.toFormat("yyyy-MM-dd")
                  )
                }
                label={t("registerPatient.dateOfBirth")}
                error={inputErrors.get(InputsThatNeedValidation.BirthDate)}
              />
            )}

            <CustomPhoneInput
              dataTestId="phone"
              useCountrySelect={!config.isAnthem}
              value={externalUser?.phoneNumber}
              onChange={(value) => {
                inputErrors.set(InputsThatNeedValidation.Phonenumber, "");
                updateExternalUserProperty("phoneNumber", value);
              }}
              onBlur={handlePhoneInputBlur}
              defaultCountry={config.isAnthem ? "US" : getDefaultCountry()}
              ref={phoneNumberInput}
              autoFocus={!config.isAnthem}
              required={true}
              label={t("registerPatient.phone")}
              error={inputErrors.get(InputsThatNeedValidation.Phonenumber)}
            />

            {!config.isAnthem && (
              <CustomSelect
                label={t("registerPatient.underLyingRisk")}
                value={externalUser?.category ?? 1}
                onChange={(e) =>
                  updateExternalUserProperty("category", e.target.value)
                }
                renderOption={(option) => t(option?.text)}
                options={getUnderlyingRiskTypes()}
              />
            )}
            {config.isAnthem && (
              <Input
                label={t("registerPatient.email")}
                dataTestId="email"
                initialValue={externalUser?.email}
                placeHolder="someone@example.com"
                onChange={(value) => updateExternalUserProperty("email", value)}
              />
            )}

            {externaluser ? (
              <Input
                label={t("registerPatient.userType")}
                dataTestId="userType"
                initialValue={selectedExternalUserType?.name}
                disabled
              />
            ) : (
              <CustomSelect
                label={t("registerPatient.userType")}
                value={selectedExternalUserTypeId}
                valueKey="id"
                onChange={handleSetExternalUserType}
                renderOption={(option) => t(option?.name)}
                options={enrolmentExternalUserTypes ?? []}
              />
            )}

            {config.isAnthem && (
              <Input
                label="HRS Member ID"
                dataTestId="HRSMember"
                initialValue={externalUser?.anthemHRSID}
                onChange={(value) =>
                  updateExternalUserProperty("anthemHRSID", value)
                }
              />
            )}

            <Input
              label={config.isAnthem ? "HCID" : t("registerPatient.patientId")}
              dataTestId="hcid"
              initialValue={externalUser?.identifier}
              onChange={(value) =>
                updateExternalUserProperty("identifier", value)
              }
              required={true}
              ref={identifierInput}
              error={inputErrors.get(InputsThatNeedValidation.Identifier)}
            />

            {config.isAnthem && (
              <Input
                label="Case ID"
                initialValue={externalUser?.caseIdentifier}
                onChange={(value) =>
                  updateExternalUserProperty("caseIdentifier", value)
                }
              />
            )}
            {externaluser && (
              <>
                <Input
                  label={t("registerPatient.accessCode")}
                  initialValue={externalUser?.pinCode ?? ""}
                  disabled
                />

                <Input
                  label={t("registerPatient.accessUrl")}
                  initialValue={externalUser?.pinCodeUrl ?? ""}
                  disabled
                />

                {config.isAnthem && (
                  <>
                    <Input
                      label="MBR Key"
                      initialValue={externalUser?.anthemMbrKey}
                      onChange={(value) =>
                        updateExternalUserProperty("anthemMbrKey", value)
                      }
                    />
                    <Input
                      label="MCID"
                      initialValue={externalUser?.anthemMCID}
                      onChange={(value) =>
                        updateExternalUserProperty("anthemMCID", value)
                      }
                    />
                    <CustomSelect
                      label={"LOB"}
                      value={externalUser?.lineOfBusiness}
                      onChange={(e) =>
                        updateExternalUserProperty(
                          "lineOfBusiness",
                          e.target.value
                        )
                      }
                      options={linesOfBusiness.map((lineOfBusiness) => ({
                        value: lineOfBusiness
                      }))}
                      className={styles.select}
                    />
                    <CustomSelect
                      label={"Outreach Type"}
                      value={externalUser?.outreachType}
                      options={getOutreachTypeOptions()}
                      renderOption={(option) => option?.text}
                      onChange={(e) =>
                        updateExternalUserProperty(
                          "outreachType",
                          e.target.value
                        )
                      }
                      className={styles.select}
                    />
                    <Input
                      label={"Brand"}
                      value={externalUser?.brand}
                      disabled
                    />
                    <Input
                      label={t("registerPatient.patientBond")}
                      initialValue={externalUser?.patientBond}
                      disabled
                    />
                    <Input
                      label={t("registerPatient.relationship")}
                      initialValue={getRelationshipText(
                        externalUser?.relationship ?? ""
                      )}
                      disabled
                    />
                    <CustomSelect
                      label={"Member state"}
                      value={externalUser?.memberState}
                      onChange={(e) =>
                        updateExternalUserProperty(
                          "memberState",
                          e.target.value
                        )
                      }
                      renderOption={(option) => option?.text}
                      options={states.map((state) => ({
                        text: state.name,
                        value: state.code
                      }))}
                      className={styles.select}
                    />
                    <CustomSelect
                      label={"Plan state"}
                      value={externalUser?.planState}
                      onChange={(e) =>
                        updateExternalUserProperty("planState", e.target.value)
                      }
                      renderOption={(option) => option?.text}
                      options={states.map((state) => ({
                        text: state.name,
                        value: state.code
                      }))}
                      className={styles.select}
                    />
                  </>
                )}
              </>
            )}
            {program?.variablesSelectableOnInvite && (
              <div>
                <CustomCheckBoxSelect
                  onChange={(values) => {
                    setSelectedConditions(values.join(";"));
                  }}
                  options={program.variablesSelectableOnInvite
                    .map((variable) => ({
                      value: variable.name,
                      text: variable.title ?? variable.name
                    }))
                    .sort((a, b) => (a.text > b.text ? 1 : -1))}
                  selected={selectedConditions.split(";")}
                  label={t("registerPatient.layers")}
                  isReadOnly={
                    externalUser?.enrolmentStatus === EnrolmentStatuses.Enrolled
                  }
                  required={
                    program?.variableSelectionRequiredOnInvite &&
                    !externalUser?.id
                  }
                />
              </div>
            )}
            {isMedicareProgram(program) && (
              <>
                <CustomSelect
                  label={t("registerPatient.groupNumber")}
                  value={externalUser?.groupNumber}
                  onChange={(e) => updateExternalUserGroup(e.target.value)}
                  renderOption={(option) => option?.text}
                  allowSearch
                  options={(groups || [])?.map((group) => ({
                    text: group.number,
                    value: group.number
                  }))}
                />
                <CustomSelect
                  label={t("registerPatient.groupName")}
                  value={externalUser?.groupName}
                  onChange={(e) =>
                    updateExternalUserGroupNumber(e.target.value)
                  }
                  renderOption={(option) => option?.text}
                  allowSearch
                  options={(groups || []).map((group) => ({
                    text: group.shortName || group.name,
                    value: group.name
                  }))}
                />
              </>
            )}
            {config.isAnthem && (
              <>
                <CustomSelect
                  label={t("registerPatient.consentToCall")}
                  value={externalUser?.enrolmentConsentToCall}
                  onChange={(e) =>
                    updateExternalUserProperty(
                      "enrolmentConsentToCall",
                      parseInt(e.target.value)
                    )
                  }
                  renderOption={(option) => t(option?.text)}
                  options={getCallConsentTypes(false)}
                />

                <CustomSelect
                  label={t("registerPatient.callrecordingdisclaimer")}
                  value={externalUser?.enrolmentCallRecordingDisclaimer}
                  onChange={(e) =>
                    updateExternalUserProperty(
                      "enrolmentCallRecordingDisclaimer",
                      parseInt(e.target.value)
                    )
                  }
                  renderOption={(option) => t(option?.text)}
                  options={getCallRecordingDisclaimerTypes(false)}
                />

                <CustomSelect
                  label={t("registerPatient.enrolment")}
                  value={enrolmentStatus}
                  onChange={(e) => setEnrolmentStatus(parseInt(e.target.value))}
                  renderOption={(option) => t(option?.text)}
                  options={getEnrolmentFilterTypes({ includeAll: false })}
                />

                <CustomSelect
                  label={t("registerPatient.hipaaverified")}
                  value={
                    externalUser?.enrolmentHipaaVerified ?? HipaaStatus.Unknown
                  }
                  onChange={(e) =>
                    updateExternalUserProperty(
                      "enrolmentHipaaVerified",
                      parseInt(e.target.value)
                    )
                  }
                  renderOption={(option) => t(option?.text)}
                  options={getHipaaCertifiedTypes()}
                />

                <Input
                  label={t("registerPatient.providerName")}
                  initialValue={externalUser?.providerName}
                  onChange={(value) =>
                    updateExternalUserProperty("providerName", value)
                  }
                />

                <Input
                  label={t("registerPatient.providerEmail")}
                  initialValue={externalUser?.providerEmail}
                  onChange={(value) =>
                    updateExternalUserProperty("providerEmail", value)
                  }
                />

                <Input
                  label="Care Compass ID"
                  initialValue={externalUser?.careCompassExternalUserID}
                  onChange={(value) =>
                    updateExternalUserProperty(
                      "careCompassExternalUserID",
                      value
                    )
                  }
                />
              </>
            )}
            {selectedExternalUserType &&
              isCancerProgramByUserType(selectedExternalUserType) && (
                <CustomSelect
                  label={t("registerPatient.cancerType")}
                  value={externalUser?.diseaseName}
                  onChange={(e) =>
                    updateExternalUserProperty("diseaseName", e.target.value)
                  }
                  options={getDiseaseNameOptions()}
                  renderOption={(option) => option?.text}
                />
              )}
            {filteredExternalLinkOptions.length > 0 && (
              <CustomSelect
                label={t("registerPatient.externalLinks")}
                placeholder={t("registerPatient.externalLinks")}
                disabled={loading}
                options={filteredExternalLinkOptions}
                renderOption={(option) => option.value}
              />
            )}
            {externaluser && (
              <>
                {programHasBehaviour(
                  program,
                  ProgramBehaviour.RetriggeringEnabled
                ) && (
                  <>
                    {shouldAllowRetriggerUpdate ? (
                      <CustomSelect
                        label={t("retrigger.retrigger")}
                        value={getRetriggeredValue(
                          externalUser?.eligibleForRestart,
                          externalUser?.eligibleForRestartDate,
                          program?.retriggeredStatusDurationSeconds
                        )}
                        onChange={(e) =>
                          updateExternalUserProperty(
                            "eligibleForRestart",
                            Boolean(parseInt(e.target.value))
                          )
                        }
                        options={getRetriggerOptions()}
                        renderOption={(option) => option?.text}
                      />
                    ) : (
                      <Input
                        label={t("retrigger.retrigger")}
                        initialValue={getRetriggeredText(
                          externalUser?.eligibleForRestart,
                          externalUser?.eligibleForRestartDate,
                          program?.retriggeredStatusDurationSeconds
                        )}
                        disabled
                      />
                    )}
                    <Input
                      label={t("retrigger.lastRetriggerDate")}
                      initialValue={getLastRetriggerDateText()}
                      disabled
                    />
                  </>
                )}
                <Input
                  label={
                    config.isAnthem
                      ? t("registerPatient.sidekickRegistrationId")
                      : t("registerPatient.registrationId")
                  }
                  initialValue={externalUser?.id ?? ""}
                  disabled
                />
                {config.isAnthem && (
                  <Input
                    label={t("registerPatient.proxyId")}
                    initialValue={externalUser?.proxyId ?? ""}
                    disabled
                  />
                )}
              </>
            )}
          </div>
          <ExternalUserChangeLog
            externalUser={externalUser}
            externalUserHistory={externalUserHistory}
          />
          <div className={styles.error}>{error}</div>
          <div className={styles.actions}>
            {externaluser && smsSettings && (
              <button
                className="btn-secondary"
                disabled={!canSendSMS()}
                onClick={handleSMSClick}
              >
                {t("registerPatient.sendSms")}
              </button>
            )}
            {externalUser?.linkedUserId && pathname.includes("/registration") && (
              <Link
                to={`/program/${program_id}/locale/${locale}/users/${externalUser.linkedUserId}`}
                className="btn-secondary"
              >
                {t("registerPatient.openLinkedUser")}
              </Link>
            )}
            <button
              className="btn-primary"
              disabled={externaluser ? loading : !canSendSMS()}
              onClick={() => saveAndClose()}
            >
              {externaluser
                ? t("registerPatient.saveChange")
                : t("registerPatient.sendSms")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default RegisterPatient;
