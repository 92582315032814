import api from "~/api/api";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { DeviceOrderStatusData } from "~/typing/sidekickTypes";

export const getDeviceOrderStatuses = async ({
  programId,
  userId
}: {
  programId: string;
  userId: string;
}): Promise<DeviceOrderStatusData[]> => {
  try {
    const path = `/admin/programcatalogitems/${programId}/users/${userId}/externaldevices/orders`;
    const response = await api.get(path);

    return response?.data ? response?.data?.items : [];
  } catch (err) {
    displayErrorToast({ message: "Error getting device statuses" });
    return [];
  }
};
