import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./RestartPatient.module.scss";

import { UserPageContext } from "../userPage/UserPage.context";

import { restartMember } from "~/api/requests/userRequests";
import Button from "~/components/button/Button";
import Modal from "~/components/modal/Modal";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { useExternalUser } from "~/hooks/useApi/useExternalUser";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";

type RestartPatientProps = {
  close: (userDischarged: boolean) => void;
  canBeRestarted: boolean;
};

const RestartPatient = ({ close, canBeRestarted }: RestartPatientProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const { mutateUserDetail, userDetail } = useContext(UserPageContext);
  const {
    program_id = "",
    user_id = "",
    locale = ""
  } = useParams<UserURLParams>();
  const { trackPatientRestarted } = useAmplitudeTracking();

  const { externalUser, setManuallyInCache } = useExternalUser({
    externalUserId: userDetail?.externalUser?.id,
    externalUserTypeId: userDetail?.externalUser?.externalUserTypeId
  });

  const handleConfirmation = async () => {
    setLoading(true);

    const response = await restartMember({
      programId: program_id,
      userId: user_id,
      locale,
      externalUserId: externalUser?.id,
      externalUserTypeId: externalUser?.externalUserTypeId
    });

    if (response?.res?.ok) {
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.programs.users(program_id, locale)
      });
      trackPatientRestarted();
      mutateUserDetail();
      if (externalUser) {
        //Switch retrigger to false and clear the date on the locally stored external user
        setManuallyInCache({
          ...externalUser,
          eligibleForRestart: false,
          eligibleForRestartDate: undefined
        });
      }
      displaySuccessToast({ message: t("restartPatient.success") });
      close(true);
    } else {
      displayErrorToast({ message: t("restartPatient.error") });
    }

    setLoading(false);
  };

  return (
    <>
      <Modal
        title={t("restartPatient.title")}
        className={styles.restartModal}
        onClose={() => close(false)}
      >
        {canBeRestarted && (
          <div className={styles.header}>{t("restartPatient.subtitle")}</div>
        )}
        <div>
          {canBeRestarted
            ? t("restartPatient.description")
            : t("restartPatient.activeOtherProgram")}
        </div>
        <div className={styles.buttons}>
          <Button disabled={loading} inverted onClick={() => close(false)}>
            {t("dischargePatient.cancel")}
          </Button>
          <Button
            isLoading={loading}
            disabled={loading || !canBeRestarted}
            onClick={() => handleConfirmation()}
          >
            {t("restartPatient.restart")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RestartPatient;
