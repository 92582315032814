import { DateTime } from "luxon";

import api from "../api";

import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { MissionScore } from "~/typing/sidekickTypes";

export const getMissionScores = async ({
  userId,
  programCatalogItemId,
  locale,
  missionId,
  startDate,
  endDate
}: {
  userId: string;
  programCatalogItemId: string;
  locale: string;
  startDate: DateTime;
  endDate: DateTime;
  missionId: string;
}) => {
  const formattedStartDate = startDate.toISODate();
  const formattedEndDate = endDate.toISODate();
  try {
    const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/users/${userId}/scores?missionId=${missionId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;

    const response = await api.get<{ items: MissionScore[] }>(path);

    return response?.data?.items ?? [];
  } catch (err) {
    displayErrorToast({
      message: t("errors.fetch", { resource: "Mission scores" })
    });
    throw err;
  }
};
