import api from "../api";

import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { SurveyResult } from "~/typing/sidekickTypes";

export const getSurveys = async ({
  programId,
  locale
}: {
  programId: string;
  locale: string;
}) => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/surveys`;
    const surveysRes = await api.get(path);

    const surveys = surveysRes?.data?.items;

    return surveys;
  } catch (err) {
    return [];
  }
};

export const getSurveyResults = async ({
  programId,
  locale,
  userId,
  surveyId
}: {
  programId: string;
  locale: string;
  userId: string;
  surveyId: string;
}): Promise<SurveyResult[]> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/surveys/${surveyId}/surveyresults/`;
    const surveyResultsRes = await api.get(path);

    const surveResults = surveyResultsRes?.data?.items;

    return surveResults;
  } catch (err) {
    return [];
  }
};

export const setCoachRatingForSurvey = async ({
  programId,
  locale,
  userId,
  surveyResultId,
  coachRatingValue
}: {
  programId: string;
  locale: string;
  userId: string;
  surveyResultId: string;
  coachRatingValue: number;
}) => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/surveyresults/${surveyResultId}/coachrating`;
    const ratingRes = await api.post(path, { coachRatingValue });

    if (ratingRes?.res?.ok) {
      displaySuccessToast({ message: t("pro.success") });
      return ratingRes.data;
    }
  } catch (err) {
    displayErrorToast({ message: t("pro.error") });
  }
};
