import api from "../api";

import { ProgramSpecialty, UserSpecialty } from "~/typing/sidekickTypes";

export const assignSpecialtyToCoach = async (specialtyId: string) => {
  try {
    const response = await api.post<UserSpecialty>("/coach/me/specialty", {
      specialtyId
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getProgramSpecialties = async (
  programId: string,
  locale: string
) => {
  try {
    const response = await api.get<{ items: ProgramSpecialty[] }>(
      `/coach/programs/${programId}/locales/${locale}/specialties`
    );

    return response?.data?.items;
  } catch (error) {
    throw error;
  }
};
