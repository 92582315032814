import { gql } from "graphql-request";
import { t } from "i18next";

import { graphqlClient } from "./client";
import { handleGraphQLError } from "./handlers";

import {
  CareManagerTasksDto,
  CarePriorities,
  MutationCalculateRankingScoreArgs,
  MutationMarkMessageSuggestionAsSentArgs,
  UserContextInfo
} from "~/typing/graphql/types";

export const getCareManagerTasks = async (
  coachId: number
): Promise<CareManagerTasksDto> => {
  const query = gql`
    query GetCareManagerTasks($coachId: Long!) {
      getCareManagerTasks(coachId: $coachId) {
        careManagerTaskDtos {
          estimatedUserCompletionTimeSeconds
          onHold
          onHoldUntil
          profilePictureUrl
          carePriorities
          timeOfRanking
          userFullName
          userId
          programCatalogItemName
        }
        estimatedChurnRiskCompletionTime
        estimatedFirstWeekMessageCompletionTime
        estimatedUnansweredMessageCompletionTime
        estimatedWeeklyFeedbackCompletionTime
      }
    }
  `;

  try {
    return await graphqlClient
      .request(query, {
        coachId
      })
      .then((data: any) => data?.getCareManagerTasks || {});
  } catch (error) {
    handleGraphQLError({ error, errorId: "graphq-ql-get-cm-tasks" });
    throw error;
  }
};

export const getUserContextInfo = async (
  userId: string
): Promise<UserContextInfo> => {
  const query = gql`
    query GetUserContextInfo($userId: Long!) {
      getUserContextInfo(userId: $userId) {
        dayNumber
        locale
        programCatalogItemName
        userName
        weekNumber
        programCatalogItemId
        userActivityLastWeek {
          activeDates
          userId
        }
      }
    }
  `;

  try {
    return await graphqlClient
      .request(query, {
        userId
      })
      .then((data: any) => data?.getUserContextInfo || {});
  } catch (error) {
    handleGraphQLError({
      error,
      errorMessage: t("nextStep.errors.fetchingUserInfo")
    });
    throw error;
  }
};

export const getCarePriorities = async (
  userId: string
): Promise<CarePriorities> => {
  const query = gql`
    query GetCarePriorities($userId: Long!) {
      getCarePriorities(userId: $userId) {
        priorities {
          metadata
          type
        }
        timeOfRanking
      }
    }
  `;

  try {
    return await graphqlClient
      .request(query, {
        userId
      })
      .then((data: any) => data?.getCarePriorities || {});
  } catch (error) {
    handleGraphQLError({
      error,
      errorMessage: t("nextStep.errors.fetchingCarePriorities")
    });
    throw error;
  }
};

export const calculateRankingScore = async (
  args: MutationCalculateRankingScoreArgs
): Promise<boolean> => {
  const mutation = gql`
    mutation CalculateRankingScore($userId: Long!) {
      calculateRankingScore(userId: $userId)
    }
  `;

  try {
    return await graphqlClient
      .request(mutation, {
        userId: args.userId
      })
      .then((data: any) => data?.calculateRankingScore || {});
  } catch (error: any) {
    handleGraphQLError({
      error,
      errorMessage: t("nextStep.errors.calculateRankingScore")
    });
    throw error;
  }
};

export const markMessageSuggestionAsSent = async (
  args: MutationMarkMessageSuggestionAsSentArgs
): Promise<number> => {
  const mutation = gql`
    mutation MarkMessageSuggestionAsSent($messageSuggestionId: String!) {
      markMessageSuggestionAsSent(messageSuggestionId: $messageSuggestionId)
    }
  `;

  try {
    return await graphqlClient
      .request(mutation, {
        messageSuggestionId: args.messageSuggestionId
      })
      .then((data: any) => data?.markMessageSuggestionAsSent || {});
  } catch (error: any) {
    handleGraphQLError({
      error,
      errorMessage: t("nextStep.errors.markMessageSuggestionAsSent")
    });
    throw error;
  }
};
