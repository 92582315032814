import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import PhoneInput, { Country } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

import styles from "./CustomPhoneInput.module.scss";

import getCountryFlagSrc from "~/helpers/getCountryFlagSrc";
import { t } from "~/i18n";

type CustomPhoneInputProps = {
  useCountrySelect: boolean;
  value?: string;
  onChange: (value: string) => void;
  defaultCountry: string;
  onBlur?: () => void;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;
  label?: string;
  error?: string;
  dataTestId?: string;
};

interface IProps extends CustomPhoneInputProps {
  inputRef: ForwardedRef<HTMLInputElement>;
}

const CustomPhoneInput = forwardRef<HTMLInputElement, CustomPhoneInputProps>(
  (props, ref) => <CustomPhoneInputComponent {...props} inputRef={ref} />
);

const CustomPhoneInputComponent = ({
  useCountrySelect,
  className,
  value,
  onChange,
  onBlur,
  defaultCountry,
  inputRef,
  required,
  label,
  error,
  dataTestId,
  ...rest
}: IProps) => {
  const renderSelectedCountryCodeOption = (country) => {
    return <img src={getCountryFlagSrc(country.countryName)} />;
  };

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  const handleOnChange = (value: string) => {
    setShowError(false);
    onChange(value);
  };

  return (
    <div>
      {label && (
        <label className="label label--block">
          {label}
          {required && <span className="required-star">*</span>}
        </label>
      )}
      {useCountrySelect ? (
        <PhoneInput
          defaultCountry={defaultCountry as Country}
          flagComponent={renderSelectedCountryCodeOption}
          className={`${styles.phoneInput} ${className || ""} ${
            showError && styles.errorBorder
          }`}
          ref={inputRef as any}
          value={value as any}
          onChange={handleOnChange}
          onBlur={onBlur}
          data-testid={dataTestId}
          {...rest}
          placeholder={t("general.phoneNumber")}
        />
      ) : (
        <Input
          data-testid={dataTestId}
          defaultCountry={defaultCountry as Country}
          className={`${styles.phoneInputNoFlag} input ${className || ""} ${
            showError && styles.errorBorder
          }`}
          ref={inputRef}
          value={value as any}
          onChange={handleOnChange}
          onBlur={onBlur}
          label={label}
          {...rest}
          placeholder={t("general.phoneNumber")}
        />
      )}
      {error && showError && <p className={styles.error}>{error}</p>}
    </div>
  );
};

CustomPhoneInput.displayName = "CustomPhoneInput";
export default CustomPhoneInput;
