import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";
import { Settings } from "luxon";

import api from "~/api/baseApi";
import config from "~/config";
import { identifyAmplitudeUser } from "~/tracking/amplitudeHelpers";
import {
  AuthenticatedUser,
  EnrichedAuthenticatedUser
} from "~/typing/sidekickTypes";

export const fetchUser = createAsyncThunk(
  "user/fetch",
  async (_, { dispatch }) => {
    try {
      const response = await api.get(`/admin/users/user/authenticated`);

      if (response?.res?.status === 403) {
        throw new Error(
          i18next.t(
            "login.errors.missingPrivileges",
            "Missing privileges. Please contact an admin to grant you privileges."
          )
        );
      }

      if (!response?.res?.ok) return;

      const userId = response?.data.id;

      if (userId) dispatch(fetchUserSettings(userId));

      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async ({ userId, update }: { userId: string; update: any }) => {
    const res = await api.put(`/coach/users/${userId}`, update);

    return res?.data;
  }
);

const setUSSettings = (settings) => {
  const USSettings = {
    ...settings,
    useMetric: false
  };

  Settings.defaultLocale = "en-US";
  return USSettings;
};

export const fetchUserSettings = createAsyncThunk(
  "user/fetchSettings",
  async (userId: number) => {
    const res = await api.get(`/admin/users/${userId}/settings`);
    return res?.data;
  }
);

export const setUserSetting = createAsyncThunk(
  "user/setSetting",
  async ({
    userId,
    settingName,
    setting
  }: {
    userId: string;
    settingName: string;
    setting: any;
  }) => {
    const res = await api.post(
      `/admin/users/${userId}/settings/${settingName}`,
      setting
    );

    return res?.data;
  }
);

export const uploadProfilePic = createAsyncThunk(
  "user/uploadProfilePic",
  async ({ userId, body }: { userId: string; body: any }) => {
    const res = await api.post(`/admin/users/${userId}/image`, body);
    return res?.data;
  }
);

function enrichUser(user: AuthenticatedUser) {
  if (!user) return;

  const enrichedUser: EnrichedAuthenticatedUser = {
    ...user,
    id: user.id || user.userId || "", // sometimes backend names it userId, sometimes it names it id
    fullName: user.name,
    locale: config.isAnthem ? "en" : user.locale ?? "is"
  };

  return enrichedUser;
}

interface UserState {
  user?: EnrichedAuthenticatedUser | undefined;
  settings?: { [key: string]: string };
  userStatus?: string;
  imageStatus?: string;
  settingsStatus?: string;
}

const initialState: UserState = {
  user: undefined,
  settings: {},
  imageStatus: "",
  userStatus: "",
  settingsStatus: ""
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.userStatus = !state.user ? "initialLoad" : "loading";
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = enrichUser(action.payload);
      state.userStatus = "success";
      if (action.payload?.id || action.payload?.userId) {
        identifyAmplitudeUser({
          role: action.payload?.title ?? null,
          privileges: action.payload?.privileges,
          userId: action.payload?.id || action.payload?.userId
        });
      }
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.userStatus = "failed";
    });
    builder.addCase(updateUser.pending, (state) => {
      state.userStatus = "loading";
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = enrichUser(action.payload);
      state.userStatus = "success";
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.userStatus = "failed";
      console.error("Failed updating user");
      console.trace(action.error);
    });
    builder.addCase(uploadProfilePic.pending, (state) => {
      state.imageStatus = "loading";
    });
    builder.addCase(uploadProfilePic.fulfilled, (state, action) => {
      if (!state.user) return;
      state.user.imageId = action?.payload?.imageId || 0;
      state.user.imageHref = action?.payload?.imageHref || "";
      state.imageStatus = "success";
    });
    builder.addCase(uploadProfilePic.rejected, (state, action) => {
      state.imageStatus = "failed";
      console.error("Failed uploading profile image");
      console.trace(action.error);
    });
    builder.addCase(fetchUserSettings.pending, (state) => {
      state.settingsStatus = "loading";
    });
    builder.addCase(fetchUserSettings.fulfilled, (state, action) => {
      let settings = {};

      action.payload?.items?.forEach((item) => {
        settings[item.name] =
          item.booleanValue !== undefined ? item.booleanValue : item.value;
      });

      if (config.isAnthem) {
        settings = setUSSettings(settings);
      }

      state.settings = settings;
      state.settingsStatus = "success";
    });
    builder.addCase(fetchUserSettings.rejected, (state, action) => {
      state.settingsStatus = "failed";
      console.error("Failed fetching user settings");
      console.trace(action.error);
    });
    builder.addCase(setUserSetting.pending, (state) => {
      state.settingsStatus = "loading";
    });
    builder.addCase(setUserSetting.fulfilled, (state, action) => {
      if (!state.settings) state.settings = {};
      state.settings[action.payload?.name] =
        action.payload?.booleanValue !== undefined
          ? action.payload?.booleanValue
          : action.payload?.value;

      state.settingsStatus = "success";
    });
    builder.addCase(setUserSetting.rejected, (state, action) => {
      state.settingsStatus = "failed";
      console.error("Failed saving user setting");
      console.trace(action.error);
    });
    builder.addCase("auth/logout/fulfilled", (state) => {
      state.user = undefined;
      state.userStatus = "";
    });
  }
});

const userReducer = userSlice.reducer;

export { userReducer };
