import { displayErrorToast } from "../../helpers/toast/displayToast";
import { t } from "../../i18n";
import api from "../api";

export type DeviceInformation = {
  description: string;
  externalDeviceVendorId: string;
  externalSku: string;
  id: string;
  name: string;
  subtitle: string;
  imageHref?: string;
};

export const orderDevicesForUser = async ({
  programId,
  userId,
  overrideAllowed,
  email,
  firstName,
  lastName,
  address,
  address2,
  city,
  state,
  postalCode,
  phone,
  deviceIds
}: {
  userId: string;
  programId: string;
  overrideAllowed?: boolean;
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
  deviceIds: string[];
}) => {
  try {
    const res = await api.post(
      `/admin/programcatalogitems/${programId}/users/${userId}/externaldevices/order`,
      {
        overrideAllowed,
        email,
        firstName,
        lastName,
        address,
        address2,
        city,
        state,
        postalCode,
        phone,
        externalDeviceIds: deviceIds
      }
    );

    return res;
  } catch (err) {
    displayErrorToast({
      message: t("errors.fetch", { resource: "general.message" })
    });
  }
};

export const getAllowedDevices = async ({
  programId,
  userId
}: {
  userId: string;
  programId: string;
}): Promise<DeviceInformation[]> => {
  try {
    const res = await api.get(
      `/admin/programcatalogitems/${programId}/users/${userId}/externaldevices/allowed`
    );

    return res?.data.items;
  } catch (err) {
    displayErrorToast({
      message: t("errors.fetch", { resource: t("devices.allowedDevices") })
    });
    return [];
  }
};
