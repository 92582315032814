import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { t } from "i18next";

import { displayErrorToast } from "~/helpers/toast/displayToast";

export const handleGraphQLError = ({
  error,
  errorMessage = t("errors.generic"),
  errorId
}: {
  error: any;
  errorMessage?: string;
  errorId?: string;
}) => {
  const history = createBrowserHistory();
  const routesToIgnore = ["/", "/login", "/resetpassword", "/signup"];

  // TODO Currently we are getting HTTP 403 for session expiry. We need to update the backend to return a 401 instead.
  // Once that is done, we can remove the 403 check and only check for 401.
  if (error?.response?.status === 403) {
    if (!routesToIgnore.includes(history.location.pathname)) {
      history.push("/login?sessionExpired=true", {
        from: history.location
      });
      history.go(0);
    }
  } else {
    Sentry.captureException(error);
    displayErrorToast({ message: errorMessage, id: errorId });
  }
};
