import { useState, useCallback } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./Login.module.scss";

import config from "~/config";

type LoginProps = {
  onLogin: (email: string, password: string) => void;
  error?: string;
  isLoading?: boolean;
};

const Login = ({ onLogin, error, isLoading }: LoginProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPW, setInvalidPW] = useState(false);
  const autoFocus = useCallback((el) => (el ? el.focus() : null), []);

  const validate = () => {
    let valid = true;

    // Leaving regex validation of email up to the browser
    if (!email) {
      setInvalidEmail(true);
      valid = false;
    } else {
      setInvalidEmail(false);
    }

    if (!password) {
      setInvalidPW(true);
      valid = false;
    } else {
      setInvalidPW(false);
    }

    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.remove();
    if (!validate()) return false;
    onLogin(email, password);
  };

  const formClasses = `${styles.Login} ${error ? styles["Login--error"] : ""}`;
  const emailClasses = `input input--block ${
    invalidEmail ? "input--invalid" : ""
  }`;
  const pwClasses = `input input--block ${invalidPW ? "input--invalid" : ""}`;
  const btnClasses = `btn-primary ${
    isLoading ? "styles.Login--isLoading" : ""
  }`;

  return (
    <div className={formClasses}>
      <h1>{t("login.login")}</h1>

      <form onSubmit={handleSubmit}>
        <div className={styles.flex}>
          <input
            id="email"
            type="email"
            autoComplete="username"
            placeholder={t("login.enterEmail", "Enter your email address...")}
            className={emailClasses}
            ref={autoFocus}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        {invalidEmail && (
          <p className="input__error-msg">
            {t("login.errors.missingEmail", "Please enter an email address.")}
          </p>
        )}

        <div className={styles.flex}>
          <input
            id="password"
            type="password"
            autoComplete="password"
            placeholder={t("login.enterPassword", "Enter your password...")}
            className={pwClasses}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {invalidPW && (
          <p className="input__error-msg">
            {t("login.errors.missingPassword", "Please enter a password.")}
          </p>
        )}

        <Link to="/resetpassword" className={styles.reset}>
          {t("login.forgotpassword")}
        </Link>

        {error && <p className="input__error-block">{error}</p>}

        <div className={styles.Login__actions}>
          <button type="submit" className={btnClasses} disabled={isLoading}>
            {t("login.login")}
          </button>
          <Link to="/signup">
            <button className="btn-secondary">{t("signup.signup")}</button>
          </Link>
        </div>
      </form>
      {config.isAnthem && (
        <a
          href={"/auth/anthem"}
          className={`${styles.anthemLogin} btn-primary`}
        >
          {t("login.loginAnthem")}
        </a>
      )}
    </div>
  );
};

export default Login;
