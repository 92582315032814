import * as Sentry from "@sentry/react";
import { gql } from "graphql-request";
import { t } from "i18next";

import { graphqlClient } from "./client";

import { displayErrorToast } from "~/helpers/toast/displayToast";
import { TranslationsDto, TranslationsDtoInput } from "~/typing/graphql/types";

export const getTranslations = async (
  targetLanguage: string,
  translations: TranslationsDtoInput,
  sourceLanguage: string
): Promise<TranslationsDto> => {
  const query = gql`
    query GetTranslations(
      $targetLanguage: String!
      $translations: TranslationsDtoInput!
      $sourceLanguage: String!
    ) {
      getTranslations(
        targetLanguage: $targetLanguage
        translationsDto: $translations
        sourceLanguage: $sourceLanguage
      ) {
        translations {
          text
          id
        }
      }
    }
  `;

  try {
    return await graphqlClient
      .request(query, {
        targetLanguage,
        translations,
        sourceLanguage
      })
      .then((data: any) => data?.getTranslations || {});
  } catch (error) {
    Sentry.captureException(error);
    displayErrorToast({ message: t("translation.error") });
    throw error;
  }
};
