import { useContext, useEffect, useMemo } from "react";

import MinimalBarGraph from "./minimalGraph/MinimalBarGraph";
import styles from "./NextStepGraphView.module.scss";

import { TaskPageContext } from "../../TaskPage.context";
import {
  convertMissionScoresToBarData,
  getMetricsMissionIds
} from "../NextStepUserInformation.helpers";

import useMissions from "~/hooks/useApi/useMissions";
import { usePatientMetrics } from "~/hooks/useApi/usePatientMetrics";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";

const NextStepGraphView = () => {
  const { nextUserInfo, userDetails } = useContext(TaskPageContext);

  const { trackNextStepGraphViewOpened } = useAmplitudeTracking();

  const { missions } = useMissions();
  const {
    energyMissionId,
    sleepMissionId,
    stressMissionId
  } = getMetricsMissionIds(missions ?? []);

  const { sleepScores, energyScores, stressScores } = usePatientMetrics({
    userId: nextUserInfo?.user_id ?? "",
    programCatalogItemId: nextUserInfo?.program_catalog_item_id ?? "",
    locale: nextUserInfo?.locale ?? "",
    sleepMissionId,
    energyMissionId,
    stressMissionId
  });

  const lineData = useMemo(
    () =>
      convertMissionScoresToBarData({
        stressScores,
        sleepScores,
        energyScores
      }),
    [stressScores, sleepScores, energyScores]
  );

  useEffect(() => {
    trackNextStepGraphViewOpened();
  }, []);

  return (
    <div className={styles.graphs}>
      <MinimalBarGraph
        data={lineData}
        keys={[
          {
            label: t("performance.mental.sleep"),
            key: "sleep"
          },
          {
            label: t("performance.mental.energy"),
            key: "energy"
          },
          {
            label: t("performance.mental.stress"),
            key: "stress"
          }
        ]}
      />
      <MinimalBarGraph
        data={[
          ...(userDetails?.dailySummary
            .slice(0, 14) // Get latest two weeks
            .map(
              ({ date, physicalActivityMinutes, scoreCount, stepCount }) => ({
                date,
                physicalActivityMinutes: physicalActivityMinutes
                  ? physicalActivityMinutes
                  : undefined,
                scoreCount: scoreCount ? scoreCount : undefined,
                stepCount: stepCount ? stepCount : undefined
              })
            ) ?? [])
        ].reverse()}
        keys={[
          {
            label: t("performance.physical.stepCount"),
            key: "stepCount"
          },
          {
            label: t("performance.physical.activityMinutes"),
            key: "physicalActivityMinutes"
          },
          {
            label: t("performance.physical.scoreCount"),
            key: "scoreCount"
          }
        ]}
      />
    </div>
  );
};

export default NextStepGraphView;
