import { useEffect, useState } from "react";

import styles from "./Image.module.scss";

import attachmentApi from "~/api/attachmentApi";
import NoImageIcon from "~/assets/noImage.svg";
import config from "~/config";

type ImageProps = {
  className?: string;
  imageUrl?: string;
  onImageLoad?: () => void;
  onClick?: () => void;
  ribbonText?: string;
  shouldFetchAsBlob?: boolean;
  dataTestId?: string;
  onImageError?: () => void;
};

const Image = ({
  className,
  imageUrl,
  onImageLoad,
  onClick,
  ribbonText,
  shouldFetchAsBlob = false,
  dataTestId,
  onImageError
}: ImageProps) => {
  const classes = `${styles.image} ${className}`;

  const [image, setImage] = useState("");

  useEffect(() => {
    const getImageAsBlob = async () => {
      const attachmentImage = await attachmentApi.get(imageUrl ?? "");

      if (!attachmentImage?.data) {
        return;
      }

      setImage(attachmentImage.data);

      onImageLoad && onImageLoad();
    };

    if (shouldFetchAsBlob) {
      getImageAsBlob();
    }
  }, [imageUrl]);

  if (!imageUrl)
    return (
      <div className={classes}>
        <img
          data-testid={dataTestId}
          className={styles.noImage}
          src={NoImageIcon}
        />
      </div>
    );

  const finalImageUrl =
    imageUrl && imageUrl.split("?")?.length > 1
      ? `${imageUrl}&skPortal=care-${config.platform}`
      : `${imageUrl}?skPortal=care-${config.platform}`;

  if (shouldFetchAsBlob) {
    return (
      <div onClick={onClick} className={classes}>
        {image ? <img data-testid={dataTestId} src={image} /> : null}
      </div>
    );
  }

  return (
    <>
      <div onClick={onClick} className={classes}>
        <img
          data-testid={dataTestId}
          onLoad={onImageLoad}
          src={finalImageUrl}
          onError={onImageError}
        />
      </div>
      {ribbonText && (
        <div className={styles.RibbonWrapper}>
          <div className={styles.Ribbon}>{ribbonText}</div>
        </div>
      )}
    </>
  );
};
export default Image;
