import React, { useState, useEffect } from "react";

import styles from "./GoToUserPage.module.scss";

import api from "~/api/api";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import useUrlQuery from "~/hooks/useUrlQuery";

export default function GoToUserPage() {
  const query = useUrlQuery();
  const [anthemHRSID, setAnthemHRSID] = useState(query.get("memberID") || "");
  const [caseIdentifier, setCaseIdentifier] = useState(query.get("caseID") || "");
  const [userId, setUserId] = useState(query.get("userId") || "");
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(undefined);
    const path = "/coach/findexternaluserprograminfo";
    const payload = {
      anthemHRSID: anthemHRSID,
      caseIdentifier: caseIdentifier,
      linkedUserId: userId
    };

    const response = await api.post(path, payload);

    if (!response || response === null) {
      setError("There was an error in the system. Please try again later.");
      return;
    }

    const { res, data, body } = response;

    if (res.status === 200 && data) {
      const { programCatalogItemId, locale, userId } = data.items[0];
      const redirectPath = `/program/${programCatalogItemId}/locale/${locale}/users/${userId}`;
      window.location.href = redirectPath;
    }

    if (res.status >= 400) {
      setError(body.error.message);
    }
    setIsLoading(false);
  };

  //If userId is present in the query params, the user should be automatically redirected,
  //so we submit the form without user interaction
  useEffect(() => {
    if (userId) {
      handleSubmit(new Event("submit"));
    }
  }, []);

  return (
    <SentryErrorBoundary transactionName="GoToUserPage">
      <div className={styles.GoToUserPage}>
        <h1 className={styles.title}>Go to user</h1>
        <form onSubmit={handleSubmit}>
          {config.isAnthem && (
            <input
              type="text"
              placeholder="anthemHRSID / memberID"
              className="input"
              autoFocus
              value={anthemHRSID}
              onChange={(e) => setAnthemHRSID(e.target.value)}
            />
          )}
          {config.isAnthem && (
            <input
              type="text"
              placeholder="caseIdentifier"
              className="input"
              value={caseIdentifier}
              onChange={(e) => setCaseIdentifier(e.target.value)}
            />
          )}
          <input
            type="text"
            placeholder="User ID"
            className="input"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <button type="submit" className="btn-primary" disabled={isLoading}>
            Go to user
          </button>
          {error && <p className={styles.error}>{error}</p>}
        </form>
      </div>
    </SentryErrorBoundary>
  );
}