import { createBrowserHistory } from "history";

import config from "~/config";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

interface ApiRequestParams {
  path: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  payload?: any;
  returnError?: boolean;
}

type ApiRequestResponse<T> = {
  res: Response;
  data?: T;
  body?: any;
};

async function apiRequest<T = any>({
  path,
  method,
  payload,
  returnError = false
}: ApiRequestParams): Promise<ApiRequestResponse<T> | null> {
  const reqConfig: RequestInit = {
    method: method,
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-SK-PORTAL": `care-${config.platform}`,
      Pragma: "no-cache",
      "Cache-Control": "stale-while-revalidate=86400"
    }),
    credentials: "include"
  };

  if (payload) {
    reqConfig.body = JSON.stringify(payload);
  }

  try {
    const res = await fetch(config.apiBaseUrl + path, reqConfig);

    // body sometimes empty on DELETE responses
    let body;
    try {
      body = await res.json();
    } catch {
      body = {};
    }

    if (!res?.ok) {
      if (res?.status === 401) {
        const history = createBrowserHistory();
        const routesToIgnore = ["/", "/login", "/resetpassword", "/signup"];

        if (!routesToIgnore.includes(history.location.pathname)) {
          history.push("/login?sessionExpired=true", {
            from: history.location
          });
          history.go(0);
        }
      } else if (res?.status === 403) {
        const history = createBrowserHistory();

        if (
          history.location.pathname !== "/login" &&
          !(window as any).Cypress
        ) {
          displayErrorToast({
            message: t("login.errors.missingPrivileges"),
            id: "api-missing-privilege"
          });
        }

        console.error(
          `Missing privileges ${res?.status}: ${body.error?.message}`,
          { body: body }
        );

        return { res, body };
      } else {
        console.error(
          `Request to backend returned an error ${res?.status}: ${body.error?.message}`,
          { body: body }
        );
        return { res, body };
      }

      if (returnError) {
        return { res: res, body: body };
      } else {
        return null;
      }
    }

    return { res, data: body };
  } catch (err) {
    console.error("Request to backend failed", {
      err: err
    });

    return null;
  }
}

export default {
  get: async <T = any>(path: string) => {
    return apiRequest<T>({ path, method: "GET" });
  },

  post: async <T = any>(path: string, payload: any, returnError?: boolean) => {
    return apiRequest<T>({ path, method: "POST", payload, returnError });
  },

  put: async <T = any>(path: string, payload: any) => {
    return apiRequest<T>({ path, method: "PUT", payload });
  },

  delete: async (path: string, returnError?: boolean) => {
    return apiRequest({ path, method: "DELETE", payload: null, returnError });
  }
};
