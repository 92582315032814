import config from "~/config";
import { clockTime } from "~/helpers/date/dateHelpers";
import { t } from "~/i18n";
import { CommunicationPreferencesData } from "~/typing/carePortalTypes";

export const buildPreferenceString = (
  optedOutOfSms: boolean,
  preferences?: CommunicationPreferencesData,
  timezone?: string
) => {
  const variables: string[] = [];
  if (!preferences) {
    return t("communicationPreferences.notDefined");
  }
  if (
    preferences.agreeableToSms === undefined &&
    preferences.agreeableToPhone === undefined &&
    preferences.agreeableToEmail === undefined
  ) {
    return t("communicationPreferences.notDefined");
  }

  if (preferences.agreeableToSms !== undefined) {
    !optedOutOfSms;
    preferences.agreeableToSms && !optedOutOfSms
      ? variables.push(t("general.sms"))
      : variables.push(t("communicationPreferences.noSms"));
  }
  if (preferences.agreeableToPhone !== undefined) {
    preferences.agreeableToPhone
      ? variables.push(t("general.phone"))
      : variables.push(t("communicationPreferences.noPhone"));
  }
  if (preferences.agreeableToEmail !== undefined) {
    preferences.agreeableToEmail
      ? variables.push(t("general.email"))
      : variables.push(t("communicationPreferences.noEmail"));
  }
  if (preferences.availableTimes?.length ?? 0 > 0) {
    variables.push(
      `Available times: ${preferences.availableTimes
        ?.map((time) =>
          time.startTime && time.endTime
            ? `${clockTime(time.startTime, !config.isAnthem)} - ${clockTime(
                time.endTime,
                !config.isAnthem
              )}`
            : ""
        )
        .join(", ")
        .concat(timezone && config.isAnthem ? ` (${timezone})` : "")}`
    );
  }
  return variables.join(", ");
};
