import { DateTime } from "luxon";
import { useState, useReducer, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LegendType } from "recharts";

import { convertWeight } from "./convertWeight";
import styles from "./WeightHistoryPage.module.scss";
import weightHistoryReducer, {
  WeightHistoryActions
} from "./weightHistoryReducer";

import { InformationPageContext } from "../informationPageContext";
import BaseWeightModal from "../modals/baseWeight/BaseWeightModal";

import { getWeightDataForUser } from "~/api/requests/weightRequests";
import ChartDetail from "~/components/charts/ChartDetail";
import CustomBarChart from "~/components/charts/CustomBarChart";
import DateRangeNavigator from "~/components/dateRangeNavigator/DateRangeNavigator";
import Modal from "~/components/modal/Modal";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import changeMonth from "~/helpers/date/changeMonth";
import filterDataByWeekOrMonth from "~/helpers/date/filterDataByWeekOrMonth";
import round from "~/helpers/number/round";
import useMissions from "~/hooks/useApi/useMissions";
import useUseMetric from "~/hooks/useUseMetric";
import colors from "~/styles/colors";
import { UserURLParams } from "~/typing/carePortalTypes";

type WeightHistoryPageProps = {
  onClose: () => void;
  doesProgramUseScale?: boolean;
};

const WeightHistoryPage = ({
  onClose,
  doesProgramUseScale
}: WeightHistoryPageProps) => {
  const { t } = useTranslation();
  const [useWeek, setUseWeek] = useState(true);
  const [showBaseWeightModal, setShowBaseWeightModal] = useState(false);
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();
  const { missions } = useMissions();
  const weightMission = missions?.find((mission) => mission.name === "Weight");
  const [weightData, dispatch] = useReducer(weightHistoryReducer, {
    weightHistory: []
  });
  const { baseWeight, baseWeightEnabled } = useContext(InformationPageContext);

  const useMetric = useUseMetric();
  const now = DateTime.utc();
  const [endDate, setEndDate] = useState(now);
  const [startDate, setStartDate] = useState(
    now.minus({ days: useWeek ? 7 : 31 })
  );

  const getWeightData = async () => {
    if (weightMission) {
      const weightData = await getWeightDataForUser(
        program_id,
        locale,
        user_id,
        weightMission.id,
        startDate,
        endDate
      );

      dispatch({
        type: WeightHistoryActions.Fetch,
        payload: weightData || [],
        useMetric: useMetric
      });
    }
  };

  useEffect(() => {
    getWeightData();
  }, [weightMission, endDate]);

  useEffect(() => {
    if (baseWeight) {
      dispatch({
        type: WeightHistoryActions.AddBaseWeight,
        payload: { weight: baseWeight.value }
      });
    }
  }, [baseWeight]);

  const changeEndDate = (multiplier: number) => {
    let newEndDate: DateTime;
    if (useWeek) {
      const days = multiplier * 7;
      newEndDate = endDate.plus({ days: days });
      setStartDate(newEndDate.minus({ days: 7 }));
    } else {
      newEndDate = changeMonth(endDate, multiplier);
      setStartDate(newEndDate.startOf("month"));
    }

    setEndDate(newEndDate);
  };

  const filteredData = filterDataByWeekOrMonth(
    weightData.weightHistory,
    useWeek,
    endDate
  );

  const avg = round(
    filteredData.reduce((r, a) => {
      return r + a.weight;
    }, 0) / filteredData.length,
    1
  );

  const timeText = useWeek ? "week" : "month";

  const units = useMetric ? "kg" : "lbs";

  useEffect(() => {
    if (useWeek) {
      setEndDate(now);
      setStartDate(now.minus({ days: 7 }));
    } else {
      const newEndDate = changeMonth(endDate, 0);
      setEndDate(newEndDate);
      setStartDate(newEndDate.startOf("month"));
    }
  }, [useWeek]);

  const switchData = [
    {
      label: t("time.week", "week"),
      value: true
    },
    {
      label: t("time.month", "month"),
      value: false
    }
  ];

  const getColorForBar = (dataPoint) => {
    const isToday = dataPoint.date.toISODate() === now.toISODate();
    if (dataPoint.isFromScale) {
      return isToday ? colors.neonPink100 : colors.neonPink40;
    }

    return isToday ? colors.lightBlue100 : colors.lightBlue40;
  };

  const legendData = [
    {
      value: "Scale logs",
      color: colors.neonPink100,
      type: "square" as LegendType,
      id: "scale"
    },
    {
      value: "Manual logs",
      color: colors.lightBlue100,
      type: "square" as LegendType,
      id: "manual"
    }
  ];

  const onTodayClick = () => {
    if (useWeek) {
      setEndDate(now);
      setStartDate(now.minus({ days: 7 }));
    } else {
      const newEndDate = changeMonth(now, 0);
      setEndDate(newEndDate);
      setStartDate(newEndDate.startOf("month"));
    }
  };

  return (
    <Modal
      title={t("user.health.weightHistory")}
      onClose={onClose}
      className={styles.modal}
    >
      <div className={styles.WeightHistoryPage}>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.details}>
              <ChartDetail
                title={t(`average.${timeText}`, `Average of the ${timeText}`)}
                values={{ avg: avg || "-" }}
                units={units}
                dataKeys={["avg"]}
              />
              {baseWeightEnabled && (
                <ChartDetail
                  title={t("user.health.baseWeight", "Base weight")}
                  values={{
                    baseWeight: weightData?.baseWeight
                      ? convertWeight(weightData.baseWeight, useMetric)
                      : "-"
                  }}
                  units={units}
                  dataKeys={["baseWeight"]}
                  onEditClick={() => setShowBaseWeightModal(true)}
                />
              )}
            </div>
            <div className={styles.right}>
              <ToggleSwitch
                switchData={switchData}
                setValue={setUseWeek}
                currentValue={useWeek}
                className={styles.switch}
              />
              <DateRangeNavigator
                startDate={
                  useWeek
                    ? endDate.minus({ days: 6 }).toISO() ?? ""
                    : startDate.toISO() ?? ""
                }
                endDate={endDate.toISO() ?? ""}
                increaseDate={() => changeEndDate(1)}
                decreaseDate={() => changeEndDate(-1)}
                onTodayClick={() => onTodayClick()}
                todayLabel={useWeek ? t("time.thisWeek") : t("time.thisMonth")}
              />
            </div>
          </div>
          <CustomBarChart
            data={weightData.weightHistory}
            dataKeys={["weight"]}
            today={now}
            units={units}
            useWeek={useWeek}
            allDataKeys={["weight", "isFromScale"]}
            endDate={endDate}
            getColorForBar={getColorForBar}
            legendData={doesProgramUseScale ? legendData : undefined}
            baseLine={
              baseWeightEnabled
                ? convertWeight(weightData.baseWeight ?? 0, useMetric)
                : undefined
            }
          />
        </div>
      </div>
      {showBaseWeightModal && (
        <BaseWeightModal onClose={() => setShowBaseWeightModal(false)} />
      )}
    </Modal>
  );
};

export default WeightHistoryPage;
