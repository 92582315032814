import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";

import { QueryKeyFactory } from "../useApi/queryKeysFactory";
import useUser from "../useUser";

import {
  calculateRankingScore,
  getCareManagerTasks,
  getCarePriorities,
  getUserContextInfo,
  markMessageSuggestionAsSent
} from "~/api/graphql/nextStep";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import {
  CareManagerTasksDto,
  CarePriorities,
  MutationCalculateRankingScoreArgs,
  UserContextInfo
} from "~/typing/graphql/types";

type Props = {
  userId: string;
};

export const useGetCareManagerTask = () => {
  const { user } = useUser();

  const { data, isLoading, isError, refetch, status } = useQuery({
    queryKey: QueryKeyFactory.users.careManagerTasks(user?.id),
    queryFn: () => getCareManagerTasks(parseInt(user?.id)),
    staleTime: 1000 * 60 * 10 // 10 minutes This can be adjusted based on the requirement and testing,
  });

  return {
    careManagerTasks: data as CareManagerTasksDto,
    isLoading,
    isError,
    refetch,
    status
  };
};

export const useGetUserContextInfo = (
  userId: string,
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, status } = useQuery({
    queryKey: QueryKeyFactory.users.userContextInfo(userId),
    queryFn: () => getUserContextInfo(userId),
    enabled: !!userId && !isNaN(Number(userId)) && enabled,
    retry: false
  });

  return {
    userContextInfo: data as UserContextInfo,
    isLoading,
    isError,
    refetch,
    status
  };
};

export const useGetCarePriorities = (userId: string) => {
  const { data, isLoading, isError, refetch, status } = useQuery({
    queryKey: QueryKeyFactory.users.carePriorities(userId),
    queryFn: () => getCarePriorities(userId),
    enabled: !!userId && !isNaN(Number(userId)),
    retry: false
  });

  return {
    carePriorities: data as CarePriorities,
    isLoading,
    isError,
    refetch,
    status
  };
};

export const useCalculateRankingScore = ({ userId }: Props) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: recalculateRankingScore,
    isPending,
    isError
  } = useMutation({
    mutationKey: ["calculateRankingScore", userId],
    mutationFn: (args: MutationCalculateRankingScoreArgs) =>
      calculateRankingScore(args),
    onError: () => displayErrorToast({ message: t("errors.generic") }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.users.userContextInfo(userId)
      });
    }
  });

  return {
    recalculateRankingScore,
    isPending,
    isError,
    enabled: !!userId && !isNaN(Number(userId))
  };
};

export const useMarkMessageSuggestionAsSent = () => {
  const { mutateAsync, isPending, isError } = useMutation({
    mutationKey: QueryKeyFactory.users.markMessageSuggestionAsSent(),
    mutationFn: ({ messageSuggestionId }: { messageSuggestionId: string }) =>
      markMessageSuggestionAsSent({
        messageSuggestionId: messageSuggestionId
      }),
    retry: false
  });

  return {
    markMessageSuggestionAsSent: mutateAsync,
    isPending,
    isError
  };
};
