import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import styles from "./DocumentsPage.module.scss";
import UploadDocumentModal from "./UploadDocumentModal";

import api from "~/api/api";
import DownloadIcon from "~/assets/download.svg";
import TrashIcon from "~/assets/trash.svg";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import useDocuments from "~/hooks/useApi/useDocuments";
import useProgram from "~/hooks/useApi/useProgram";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  ActionData,
  SearchData,
  UserURLParams
} from "~/typing/carePortalTypes";
import { Document } from "~/typing/sidekickTypes";

const DocumentsPage = () => {
  const { t } = useTranslation();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();
  const {
    documents,
    isLoading,
    invalidate: invalidateDocuments
  } = useDocuments(user_id);
  const {
    trackDocumentDelete,
    trackDocumentDownload,
    trackDocumentViewOpened
  } = useAmplitudeTracking();

  const { program } = useProgram({ programCatalogItemId: program_id, locale });

  useEffect(() => {
    if (!program) return;
    trackDocumentViewOpened();
  }, [program?.programCatalogItemId]);

  const searchData: SearchData = {
    data: [{ key: ["documentName"] }],
    placeholder: t(
      "documentsPage.searchPlaceholder",
      "Search for a document..."
    ),
    className: styles.search
  };

  const columns: CustomListColumn<Document>[] = [
    {
      heading: {
        text: t("documentsPage.documentName"),
        sortable: {
          sortBy: ["documentName"]
        }
      },
      render: ({ id, documentName }) => (
        <a
          href={""}
          onClick={(e) => handleOpenDocument(e, id)}
          target="_blank"
          rel="noreferrer"
          data-testid="document-name"
        >
          {documentName}
        </a>
      ),
      className: styles.name
    },
    {
      heading: {
        text: t("documentsPage.uploadDate"),

        sortable: {
          sortBy: ["modifiedDate"]
        }
      },
      className: styles.uploadDate,
      render: ({ modifiedDate }) =>
        DateTime.fromISO(modifiedDate).toLocaleString(DateTime.DATETIME_SHORT)
    },
    {
      heading: {
        text: t("general.options")
      },
      className: styles.options,
      render: ({ id, documentName }) => (
        <div data-testid="documents-page-options">
          <img
            src={DownloadIcon}
            alt="download"
            title={t("general.download")}
            onClick={() => handleDownloadDocument(id)}
          />
          <img
            src={TrashIcon}
            alt="delete"
            title={t("general.delete")}
            onClick={() => handleDelete(id, documentName)}
          />
        </div>
      )
    }
  ];

  const handleOpenDocument = async (e, id) => {
    e.preventDefault();
    const path = `/admin/users/${user_id}/documents/${id}`;
    const fileResponse = await api.get(path);

    if (fileResponse?.res?.ok && fileResponse.data?.document) {
      const byteArray = Buffer.from(fileResponse.data.document, "base64");
      const file = new Blob([byteArray], { type: "application/pdf;base64" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      displayErrorToast({ message: t("documentsPage.openError") });
    }
  };

  const handleDownloadDocument = async (id) => {
    const path = `/admin/users/${user_id}/documents/${id}`;
    const fileResponse = await api.get(path);

    if (fileResponse?.res?.ok && fileResponse.data?.document) {
      const data = fileResponse.data;
      const encodedDocument = "data:application/pdf;base64," + data.document;
      const link = document.createElement("a");
      link.download = data.documentName;
      link.href = encodedDocument;
      link.click();
      trackDocumentDownload();
    } else {
      displayErrorToast({ message: t("documentsPage.downloadError") });
    }
  };

  const handleDelete = async (id, documentName) => {
    if (
      !window.confirm(
        t("documentsPage.confirmDelete", { documentName: documentName })
      )
    ) {
      return;
    }
    const path = `/admin/users/${user_id}/documents/${id}`;
    const fileResponse = await api.delete(path);

    if (fileResponse?.res?.ok) {
      trackDocumentDelete();
      displaySuccessToast({ message: t("documentsPage.deleteSuccess") });
      invalidateDocuments();
    } else {
      displayErrorToast({ message: t("documentsPage.deleteError") });
    }
  };

  const actionData: ActionData[] = [
    {
      text: t("general.upload"),
      className: "btn-secondary",
      onClick: () => setShowUploadModal(true),
      dataTestId: "upload-document-button"
    }
  ];

  return (
    <>
      <div className={styles.container}>
        <Helmet title={t("userSidenav.documents")} defer={false} />
        <div className={styles.customListContainer}>
          <CustomList<Document>
            columns={columns}
            noEntitiesText={t("documentsPage.noDocuments")}
            entities={documents ?? []}
            searchData={searchData}
            isLoading={isLoading}
            actionData={actionData}
            customListUniqueId={`documents-list-${user_id}-${program_id}`}
          />
        </div>
      </div>
      {showUploadModal && (
        <UploadDocumentModal
          onClose={() => setShowUploadModal(false)}
          invalidateDocuments={invalidateDocuments}
        />
      )}
    </>
  );
};

export default DocumentsPage;
