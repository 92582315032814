import { QueryKey, useQuery, useQueryClient } from "@tanstack/react-query";

import api from "~/api/api";
import chatApi from "~/api/chatApi";
import { TimeInMs } from "~/constants/measurements";

async function fetcher({
  path,
  apiChoice,
  returnErrorIfEmpty
}: {
  path: string;
  apiChoice: API;
  returnErrorIfEmpty;
}) {
  const response =
    apiChoice === "admin" ? await api.get(path) : await chatApi.get(path);

  if (!response || !response?.data) {
    throw new Error("No response from API");
  }

  if (returnErrorIfEmpty && !response.data) {
    throw new Error("No response from API");
  }

  return response;
}

type API = "chat-assistant" | "admin" | "care-portal";

export default function useApi<T>({
  queryKey,
  path,
  staleTime,
  cacheTime = TimeInMs.Minute * 10,
  disabled,
  api = "admin",
  refetchInterval,
  modifyData,
  shouldRetry,
  refetchOnWindowFocus = true,
  throwErrorIfEmpty
}: {
  path: string;
  queryKey: QueryKey;
  staleTime?: number;
  cacheTime?: number;
  disabled?: boolean;
  api?: API;
  refetchInterval?: number | (() => number | false);
  modifyData?: (data?: T) => T;
  shouldRetry?: boolean;
  refetchOnWindowFocus?: boolean;
  throwErrorIfEmpty?: boolean;
}) {
  const queryClient = useQueryClient();

  interface ApiResponse {
    res: any;
    data?: T;
  }

  const { data, isLoading, isError } = useQuery<ApiResponse>({
    queryKey,
    queryFn: () =>
      fetcher({ path, apiChoice: api, returnErrorIfEmpty: throwErrorIfEmpty }),
    staleTime,
    enabled: !disabled,
    gcTime: cacheTime,
    refetchInterval,
    select: modifyData
      ? (data: ApiResponse) => ({ ...data, data: modifyData(data.data) })
      : undefined,
    retry: shouldRetry,
    refetchOnWindowFocus
  });

  return {
    data: data?.data,
    isError,
    isLoading,
    setManuallyInCache: (value?: T) =>
      queryClient.setQueryData(queryKey, { ...data, data: value }),
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey
      })
  };
}
