import { historicalNote, otherHistoricalNote } from "~/constants/notes";
import { NoteInfo } from "~/typing/sidekickTypes";

export type NotesState = NoteInfo[];

export enum NotesActions {
  Sort = "Sort",
  Add = "Add",
  Edit = "Edit"
}

type FetchAction = {
  type: NotesActions.Sort;
  payload: { notes: NoteInfo[] };
};

type AddAction = {
  type: NotesActions.Add;
  payload: NoteInfo;
};

type ArchiveAction = {
  type: NotesActions.Edit;
  payload: NoteInfo;
};

type Action = FetchAction | AddAction | ArchiveAction;

export default function reducer(state: NotesState, action: Action): NotesState {
  switch (action.type) {
    case NotesActions.Sort: {
      const notes = action.payload.notes;
      const {
        notesToSort,
        historicalNotes,
        otherHistoricalNotes
      } = notes.reduce(
        (prev, curr) => {
          if (curr.itemType === historicalNote) {
            prev.historicalNotes.push(curr);
          } else if (curr.itemType === otherHistoricalNote) {
            prev.otherHistoricalNotes.push(curr);
          } else {
            prev.notesToSort.push(curr);
          }
          return prev;
        },
        {
          notesToSort: [] as NoteInfo[],
          historicalNotes: [] as NoteInfo[],
          otherHistoricalNotes: [] as NoteInfo[]
        }
      );

      const sortedArray = notesToSort.sort((noteA, noteB) => {
        const dateA = new Date(noteA.createdDate);
        const dateB = new Date(noteB.createdDate);
        return dateB.valueOf() - dateA.valueOf();
      });

      if (historicalNotes.length > 0) {
        sortedArray.push(historicalNotes[0]);
      }

      return [...sortedArray.concat(otherHistoricalNotes)];
    }
    case NotesActions.Add: {
      return [action.payload, ...state];
    }
    case NotesActions.Edit: {
      return state.reduce<NoteInfo[]>((prev, curr) => {
        curr.id === action.payload.id
          ? prev.push(action.payload)
          : prev.push(curr);
        return prev;
      }, []);
    }
    default:
      return state;
  }
}
