import { useEffect, useRef, useState } from "react";

import styles from "./CustomTimePicker.module.scss";

type CustomTimePickerProps = {
  currentTime?: string;
  onChange: (value: string) => void;
  includeAmPm?: boolean;
  initialAmPmValue?: string;
};

const CustomTimePicker = ({
  currentTime,
  includeAmPm,
  initialAmPmValue,
  onChange
}: CustomTimePickerProps) => {
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [amPm, setAmPm] = useState(initialAmPmValue ?? "");

  const hourRef = useRef<HTMLInputElement>(null);
  const minuteRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const splitCurrentTime = currentTime?.split(":");
    let currentHour = parseInt(
      (splitCurrentTime?.length ?? 0) > 0 ? splitCurrentTime?.[0] ?? "" : ""
    );
    const currentMinute = parseInt(
      (splitCurrentTime?.length ?? 0) > 1 ? splitCurrentTime?.[1] ?? "" : ""
    );

    if (isNaN(currentHour) || isNaN(currentMinute)) {
      setMinute("");
      setHour("");
      return;
    }

    if (includeAmPm) {
      setAmPm(currentHour > 11 ? "PM" : "AM");
      currentHour =
        currentHour > 12
          ? currentHour - 12
          : currentHour === 0
          ? (currentHour += 12)
          : currentHour;
    }

    setHour(`${currentHour < 10 ? "0" : ""}${currentHour}`);
    setMinute(`${currentMinute < 10 ? "0" : ""}${currentMinute}`);
  }, []);

  const handleHourChange = (value: string) => {
    if (value.length > 2) return;
    if (value.length === 2) {
      minuteRef.current?.select();
    }
    setHour(value);
    handleChange(value, minute, amPm);
  };

  const handleMinuteChange = (value: string) => {
    if (value.length > 2) return;

    setMinute(value);
    handleChange(hour, value, amPm);
  };

  const handleAmPmChange = (value: string) => {
    setAmPm(value);
    handleChange(hour, minute, value);
  };

  const handleChange = (hour: string, minute: string, amPm: string) => {
    let parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);

    if (includeAmPm) {
      if (amPm === "PM" && parsedHour < 12) {
        parsedHour += 12;
      }
      if (amPm === "AM" && parsedHour === 12) {
        parsedHour -= 12;
      }
    }

    const formattedTime = `${parsedHour < 10 ? "0" : ""}${parsedHour}:${
      parsedMinute < 10 ? "0" : ""
    }${parsedMinute}`;

    onChange(formattedTime);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <input
          ref={hourRef}
          className={styles.input}
          onChange={(e) => handleHourChange(e.target.value)}
          placeholder={"hh"}
          value={hour}
        />
        <span>:</span>
        <input
          ref={minuteRef}
          className={styles.input}
          onChange={(e) => handleMinuteChange(e.target.value)}
          placeholder={"mm"}
          value={minute}
        />
      </div>
      {includeAmPm && (
        <select
          onChange={(e) => handleAmPmChange(e.target.value)}
          className={styles.select}
          value={amPm}
          name="ampm"
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      )}
    </div>
  );
};

export default CustomTimePicker;
