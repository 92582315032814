import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS
} from "@lexical/markdown";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { mergeRegister } from "@lexical/utils";
import classNames from "classnames";
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextFormatType
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./RichTextEditor.module.scss";
import { URL_MATCHERS, validateUrl } from "./RichTextEditor.utils";

import TextBold from "~/assets/text-bold.svg";
import TextItalic from "~/assets/text-italic.svg";
import TextStrikethrough from "~/assets/text-strikethrough.svg";

// commented for now, would be implemented after, cause need to create
// custom transformer for underline
// import TextUnderline from "~/assets/text-underline.svg";

export const ToolbarPlugin = () => {
  const LowPriority = 1;
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  // commented for now, would be implemented after, cause need to create
  // custom transformer for underline
  // const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const formattingOptions = [
    {
      command: "bold",
      isActive: isBold,
      ariaLabel: "Format Bold",
      imgSrc: TextBold
    },
    {
      command: "italic",
      isActive: isItalic,
      ariaLabel: "Format Italics",
      imgSrc: TextItalic
    },
    {
      command: "strikethrough",
      isActive: isStrikethrough,
      ariaLabel: "Format Strikethrough",
      imgSrc: TextStrikethrough
    }
    // commented for now, would be implemented after, cause need to create
    // custom transformer for underline
    // {
    //   command: "underline",
    //   isActive: isUnderline,
    //   ariaLabel: "Format Underline",
    //   imgSrc: TextUnderline
    // }
  ];

  const onChangeStyle = (command: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, command as TextFormatType);
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      // commented for now, would be implemented after, cause need to create
      // custom transformer for underline
      // setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <div className={styles.header} ref={toolbarRef}>
      {formattingOptions.map(({ command, isActive, ariaLabel, imgSrc }) => (
        <button
          key={command}
          aria-label={ariaLabel}
          onClick={() => onChangeStyle(command as TextFormatType)}
          className={classNames(styles.styleControl, {
            [styles.active]: isActive
          })}
        >
          <img src={imgSrc} />
        </button>
      ))}
    </div>
  );
};

export const MarkdownChangePlugin = ({ value, onMarkdownChange }: any) => {
  const [editor] = useLexicalComposerContext();

  const onChange = (editorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      onMarkdownChange(markdown);
    });
  };

  useEffect(() => {
    editor.update(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);

      if (value !== markdown) {
        $convertFromMarkdownString(value, TRANSFORMERS);
      }
    });
  }, [value]);

  useEffect(() => {
    const handlePaste = (event) => {
      event.preventDefault();
      const clipboardData = event.clipboardData;
      const pastedData = clipboardData.getData("Text");

      editor.update(() => {
        $convertFromMarkdownString(pastedData, TRANSFORMERS);
      });
    };

    const editorElement = editor.getRootElement();
    editorElement?.addEventListener("paste", handlePaste);

    return () => {
      editorElement?.removeEventListener("paste", handlePaste);
    };
  }, [editor]);

  return <OnChangePlugin onChange={onChange} />;
};

export const MarkdownLinkPlugin = () => {
  return (
    <>
      <LinkPlugin validateUrl={validateUrl} />
      <AutoLinkPlugin matchers={URL_MATCHERS} />
    </>
  );
};
