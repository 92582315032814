import api from "./api";

import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { Medication, MedicationLogs } from "~/typing/sidekickTypes";

export const getMedication = async ({
  userId
}: {
  userId: string;
}): Promise<Medication[]> => {
  try {
    const path = `/coach/users/${userId}/medications`;
    const res = await api.get(path);
    return res?.data?.items;
  } catch (err) {
    displayErrorToast({ message: t("medication.error") });
    return [];
  }
};

export const getMedicationLogs = async ({
  userId
}: {
  userId: string;
}): Promise<MedicationLogs[]> => {
  try {
    const path = `/coach/users/${userId}/medications/logs`;
    const res = await api.get(path);
    return res?.data?.items;
  } catch (err) {
    displayErrorToast({ message: t("medication.errorLogs") });
    return [];
  }
};
