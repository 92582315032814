import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import styles from "./CommunicationPreferences.module.scss";
import { buildPreferenceString } from "./preferencesHelpers";

import ConfirmModal from "../../../../team/ConfirmModal";

import api from "~/api/api";
import Minus from "~/assets/minus-circle.svg";
import Pencil from "~/assets/pencil.svg";
import Plus from "~/assets/plus-circle.svg";
import Modal from "~/components/modal/Modal";
import CustomSelect from "~/components/select/CustomSelect";
import CustomTimePicker from "~/components/timePicker/CustomTimePicker";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import config from "~/config";
import { USTimezoneOptions } from "~/constants/timezones";
import { arraysAreEqual } from "~/helpers/array/arrayHelpers";
import { trimmedSringsAreEqual } from "~/helpers/string/stringHelpers";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import useUserSettings from "~/hooks/useApi/useUserSettings";
import { t } from "~/i18n";
import { AppDispatch } from "~/state/store";
import { setUserSetting } from "~/state/user/userSlice";
import {
  AvailableTime,
  CommunicationPreferencesData
} from "~/typing/carePortalTypes";

const MAX_COMMENT_LENGTH = 1000;

interface CommunicationPreferencesProps {
  optedOutOfSms: boolean;
  preferences?: CommunicationPreferencesData;
  invalidatePreferences: () => void;
}

interface PreferencesPayload {
  userId?: string;
  comment?: string;
  availableTimes?: AvailableTime[];
  agreeableToPhone?: boolean;
  agreeableToSms?: boolean;
  agreeableToEmail?: boolean;
}

const CommunicationPreferences = ({
  optedOutOfSms,
  preferences,
  invalidatePreferences
}: CommunicationPreferencesProps) => {
  const { user_id = "" } = useParams<{ user_id: string }>();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const { settings, invalidate: invalidateSettings } = useUserSettings(user_id);
  const [agreeableToPhone, setAgreeableToPhone] = useState<boolean | undefined>(
    preferences?.agreeableToPhone
  );
  const [agreeableToEmail, setAgreeableToEmail] = useState<boolean | undefined>(
    preferences?.agreeableToEmail
  );
  const [agreeableToSms, setAgreeableToSms] = useState<boolean | undefined>(
    preferences?.agreeableToSms && !optedOutOfSms
  );
  const [availableTimes, setAvailableTimes] = useState<AvailableTime[]>(
    preferences?.availableTimes ? [...preferences?.availableTimes] : []
  );
  const [timezone, setTimezone] = useState(
    settings?.find((setting) => setting.name === "timezone")?.value ?? ""
  );
  const [comment, setComment] = useState(preferences?.comment);

  const dispatch = useDispatch<AppDispatch>();

  const switchData = [
    {
      label: t("general.yes"),
      value: true
    },
    {
      label: t("general.no"),
      value: false
    }
  ];

  const addNewEmptyTime = () => {
    setAvailableTimes((prev) =>
      prev.concat({
        startTime: "",
        endTime: ""
      })
    );
  };

  useEffect(() => {
    if (!timezone) {
      const currentTimezone = settings?.find(
        (setting) => setting.name === "timezone"
      )?.value;
      setTimezone(currentTimezone ?? "");
    }
  }, [settings]);

  useEffect(() => {
    if (preferences?.agreeableToEmail === undefined) return;
    setAgreeableToEmail(preferences?.agreeableToEmail);
  }, [preferences?.agreeableToEmail]);

  useEffect(() => {
    if (preferences?.agreeableToPhone === undefined) return;
    setAgreeableToPhone(preferences?.agreeableToPhone);
  }, [preferences?.agreeableToPhone]);

  useEffect(() => {
    if (preferences?.agreeableToSms === undefined) return;
    setAgreeableToSms(preferences?.agreeableToSms);
  }, [preferences?.agreeableToSms]);

  useEffect(() => {
    if (preferences?.comment === undefined) return;
    setComment(preferences?.comment);
  }, [preferences?.comment]);

  const handleAvailableTimeChange = (
    value: string,
    index: number,
    time: "start" | "end"
  ) => {
    const newAvailableTime = {
      ...availableTimes[index],
      startTime:
        time === "start" ? value?.toString() : availableTimes[index].startTime,
      endTime:
        time === "end" ? value?.toString() : availableTimes[index].endTime
    };

    availableTimes.splice(index, 1, newAvailableTime);

    setAvailableTimes([...availableTimes]);
  };

  const handleSavePreferences = async () => {
    const path = `/users/communicationPreference`;
    const payload: PreferencesPayload = {
      userId: user_id,
      comment,
      agreeableToSms,
      agreeableToEmail,
      agreeableToPhone,
      availableTimes: availableTimes.filter(
        (time) => time.endTime && time.startTime
      )
    };

    dispatch(
      setUserSetting({
        userId: user_id,
        settingName: "timezone",
        setting: { value: timezone }
      })
    );

    const res = await api.post(path, payload);

    if (res?.res?.ok) {
      displaySuccessToast({
        message: t("communicationPreferences.updateSuccess")
      });
      invalidatePreferences();
      invalidateSettings();
      close(true);
    } else {
      const errorMessageVariables: string[] = [];
      if (res?.body?.error?.message.includes("86")) {
        errorMessageVariables.push(
          t("communicationPreferences.endBeforeStartError")
        );
      }
      if (res?.body?.error?.message.includes("65|endTime")) {
        errorMessageVariables.push(t("communicationPreferences.endTimeError"));
      }
      if (res?.body?.error?.message.includes("65|startTime")) {
        errorMessageVariables.push(
          t("communicationPreferences.startTimeError")
        );
      }

      const errorMessage = errorMessageVariables?.length
        ? errorMessageVariables.join("\n")
        : t("errors.generic");
      displayErrorToast({ message: errorMessage });
    }
  };

  const handleClose = () => {
    if (hasChanged()) {
      setShowConfirmCloseModal(true);
    } else {
      close(false);
    }
  };

  const close = (saving: boolean) => {
    const currentTimezone = settings?.find(
      (setting) => setting.name === "timezone"
    )?.value;

    setAvailableTimes(preferences?.availableTimes ?? []);
    setAgreeableToPhone(preferences?.agreeableToPhone);
    setAgreeableToEmail(preferences?.agreeableToEmail);
    setAgreeableToSms(preferences?.agreeableToSms);
    setComment(preferences?.comment);
    setShowModal(false);
    setTimezone(saving ? timezone : currentTimezone ?? "");
  };

  const handleRemoveTime = (index) => {
    const tempTimes = [...availableTimes];

    tempTimes.splice(index, 1);
    setAvailableTimes(tempTimes);
  };

  const hasChanged = () => {
    const currentTimezone =
      settings?.find((setting) => setting.name === "timezone")?.value ?? "";

    return (
      preferences?.agreeableToEmail !== agreeableToEmail ||
      preferences?.agreeableToPhone !== agreeableToPhone ||
      !trimmedSringsAreEqual(preferences?.comment, comment) ||
      preferences?.agreeableToSms !== agreeableToSms ||
      !arraysAreEqual(preferences?.availableTimes ?? [], availableTimes) ||
      timezone !== currentTimezone
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.variables}>
          <p>
            <strong>{t("communicationPreferences.title")}</strong>
            {": "}
            {buildPreferenceString(
              optedOutOfSms,
              preferences,
              settings?.find((setting) => setting.name === "timezone")?.value
            )}
          </p>
          <button
            data-testid="preferences-pencil"
            className={styles.pencil}
            onClick={() => setShowModal(true)}
          >
            <img src={Pencil}></img>
          </button>
        </div>
        {preferences?.comment && (
          <p className={styles.comment}>{preferences.comment}</p>
        )}
      </div>
      {showModal && (
        <Modal
          onClose={() => handleClose()}
          title={t("communicationPreferences.title")}
          className={styles.modal}
        >
          <div>
            <div className={styles.communicationPreferences}>
              <div className={styles.preference}>
                <p>{t("communicationPreferences.agreeableSms")}</p>
                <ToggleSwitch
                  switchData={switchData}
                  setValue={(value) => setAgreeableToSms(value)}
                  currentValue={agreeableToSms}
                  disabled={optedOutOfSms}
                />
              </div>
              <div className={styles.preference}>
                <p>{t("communicationPreferences.agreeablePhone")}</p>
                <ToggleSwitch
                  switchData={switchData}
                  setValue={(value) => setAgreeableToPhone(value)}
                  currentValue={agreeableToPhone}
                />
              </div>
              <div className={styles.preference}>
                <p>{t("communicationPreferences.agreeableEmail")}</p>
                <ToggleSwitch
                  switchData={switchData}
                  setValue={(value) => setAgreeableToEmail(value)}
                  currentValue={agreeableToEmail}
                />
              </div>
              <div className={styles.availableTimes}>
                <p>{t("communicationPreferences.availableTimes")}</p>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {availableTimes.length > 0 &&
                    availableTimes?.map((time, index) => (
                      <div
                        key={`available-time-${index}-${time.id}`}
                        className={styles.timeWrapper}
                      >
                        <button
                          onClick={() => handleRemoveTime(index)}
                          className={styles.removeTime}
                        >
                          <img src={Minus} />
                        </button>
                        <div className={styles.times}>
                          <CustomTimePicker
                            currentTime={availableTimes[index].startTime}
                            includeAmPm={config.isAnthem}
                            onChange={(value) =>
                              handleAvailableTimeChange(value, index, "start")
                            }
                            initialAmPmValue={"AM"}
                          />
                          -
                          <CustomTimePicker
                            currentTime={availableTimes[index].endTime}
                            includeAmPm={config.isAnthem}
                            onChange={(value) =>
                              handleAvailableTimeChange(value, index, "end")
                            }
                            initialAmPmValue={"PM"}
                          />
                        </div>
                      </div>
                    ))}
                  <button className={styles.addTime} onClick={addNewEmptyTime}>
                    <>
                      <img src={Plus} />
                      {availableTimes?.length === 0
                        ? t("communicationPreferences.addTime")
                        : t("communicationPreferences.addAnotherTime")}
                    </>
                  </button>
                </div>
              </div>
              {availableTimes?.length > 0 && config.isAnthem && (
                <div className={styles.timezone}>
                  <p>{t("general.timezone")}</p>
                  <CustomSelect
                    value={timezone}
                    onChange={(e) => setTimezone(e.target.value)}
                    options={USTimezoneOptions.map((timezone) => ({
                      value: timezone,
                      text: timezone
                    }))}
                    className={styles.timezoneSelect}
                  />
                </div>
              )}
              <div className={styles.textareaWrapper}>
                <textarea
                  rows={3}
                  value={comment}
                  maxLength={MAX_COMMENT_LENGTH}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder={t("notes.inputPlaceholder")}
                />
                <span>
                  {comment?.length || 0} / {MAX_COMMENT_LENGTH}
                </span>
              </div>
              <div className={styles.saveWrapper}>
                <button
                  className={`${styles.save} btn btn-primary btn-sm`}
                  onClick={handleSavePreferences}
                  disabled={!hasChanged()}
                >
                  {t("general.save")}
                </button>
              </div>
            </div>
          </div>
          {showConfirmCloseModal && (
            <ConfirmModal
              modalTitle={t("communicationPreferences.unsaved")}
              confirmSubtitle={t("communicationPreferences.unsavedWarning")}
              confirmText={t("general.close")}
              cancelText={t("general.back")}
              onClose={() => {
                setShowConfirmCloseModal(false);
              }}
              onConfirm={() => {
                setShowConfirmCloseModal(false);
                close(false);
              }}
              type="warning"
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default CommunicationPreferences;
