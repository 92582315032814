import { GraphQLClient } from "graphql-request";

import config from "~/config";

const graphqlClient = new GraphQLClient(config.carePortalGraphQlUrl, {
  credentials: "include", //Include cookies in the request
  headers: {
    "X-SK-PORTAL": `care-${config.platform}`,
    Accept: "application/json",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "stale-while-revalidate=86400"
  }
});

export { graphqlClient };
