import * as Sentry from "@sentry/react";

import api from "../api";

import { FileUploadType } from "~/constants/fileUploadTypes";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import {
  ExternalUser,
  ExternalUserHistory,
  ExternalUserType,
  ImportEntry
} from "~/typing/sidekickTypes";

export const getImportableExternalUserType = async (): Promise<
  ExternalUserType[]
> => {
  try {
    const response = await api.get(`/admin/importableexternalusertypes`);

    return response?.res?.ok ? response?.data?.items : [];
  } catch (err) {
    Sentry.captureException(err);
    displayErrorToast({ message: t("errors.generic") });
    return [];
  }
};

export const getExternalUserHistory = async (
  externalUserTypeId: string,
  externalUserId: string
): Promise<ExternalUserHistory | undefined> => {
  try {
    const path = `/admin/externalusertypes/${externalUserTypeId}/externalusers/${externalUserId}/history`;

    const res = await api.get(path);
    if (res?.res?.ok) {
      return res?.data;
    } else {
      displayErrorToast({
        message: t("errors.fetch", { resource: t("user.details.history") })
      });
    }
  } catch (err) {
    Sentry.captureException(err);
    displayErrorToast({
      message: t("errors.fetch", { resource: t("user.details.history") })
    });
  }
};

export const uploadExternalUsers = async ({
  externalUserTypeName,
  uploadToken,
  encodedFile,
  fileUploadType,
  uploadName
}: {
  externalUserTypeName?: string;
  uploadToken: string;
  encodedFile: string;
  fileUploadType: FileUploadType;
  uploadName?: string;
}): Promise<{ id: number; status: string } | undefined> => {
  try {
    const response = await api.post(
      `/admin/extras/uploads?externalUserTypeName=${externalUserTypeName}`,
      {
        uploadToken,
        file: encodedFile,
        fileUploadType,
        uploadName
      }
    );

    return response?.res?.ok ? response?.data : undefined;
  } catch (err) {
    Sentry.captureException(err);
    displayErrorToast({ message: t("errors.generic") });
  }
};

export const removeExternalUserFromProgram = async (
  externalUserId: string,
  externalUserTypeId: string
) => {
  try {
    const path = `/admin/externalusertypes/${externalUserTypeId}/externalusers/${externalUserId}?removeUserFromProgram=true`;
    const response = await api.delete(path, true);

    return response;
  } catch (err) {
    throw err;
  }
};

export const getExternaUserImportEntries = async (): Promise<ImportEntry[]> => {
  try {
    const path = `/admin/extras/importentries`;
    const response = await api.get(path);

    return response?.data.items ?? [];
  } catch (err) {
    displayErrorToast({
      message: t("errors.fetch", { resource: "Import entries" })
    });
    throw err;
  }
};

export const getExternalUserLinkedUser = async ({
  programId,
  locale,
  externalUserTypeId,
  userId
}: {
  programId: string;
  locale: string;
  externalUserTypeId: string;
  userId: string;
}): Promise<any> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/externalusertypes/${externalUserTypeId}/externalusers/${userId}/`;
    const response = await api.get(path);

    return response?.data?.linkedUser;
  } catch (err) {
    displayErrorToast({
      message: t("errors.fetch", { resource: "Linked user" })
    });
    throw err;
  }
};

export const updateExternalUser = async ({
  programId,
  locale,

  externalUser
}: {
  programId: string;
  locale: string;

  externalUser: ExternalUser;
}): Promise<any> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/externalusertypes/${externalUser.externalUserTypeId}/externalusers/${externalUser.id}`;
    const response = await api.put(path, externalUser);

    return response;
  } catch (err) {
    displayErrorToast({
      message: t("errors.action", { resource: "Update external user" })
    });
    throw err;
  }
};
