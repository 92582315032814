import { DateTime } from "luxon";
import { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ExportContext } from "./exportPreviewContext";
import PrintEntityList from "./PrintEntityList";
import PrintMealCard from "./PrintMealCard";
import PrintMissions from "./PrintMissions";
import PrintNotes from "./printNotes/PrintNotes";
import PrintSurvey from "./printSurvey/PrintSurvey";
import styles from "./printUserReport.module.scss";
import PrintUserScore from "./PrintUserScore";
import StripedTable from "./StripedTable";

import Details from "../information/details/Details";
import InformationPageHeader from "../information/header/InformationPageHeader";
import { getHealthCardData } from "../information/healthCards/healthCardData";
import { buildPreferenceString } from "../information/modals/communicationPreferences/preferencesHelpers";

import api from "~/api/api";
import AnthemLogo from "~/assets/concierge-care-logo.png";
import SidekickLogo from "~/assets/sidekick-logo.svg";
import Avatar from "~/components/avatar/Avatar";
import CareTeam from "~/components/careTeam/CareTeam";
import config from "~/config";
import { isBetweenDates } from "~/helpers/date/isBetweenDates";
import getCountryFlagSrc from "~/helpers/getCountryFlagSrc";
import mmolToMg from "~/helpers/units/mmolToMg";
import { useCoachesForUser } from "~/hooks/useApi/coaches/useCoachesForUser";
import useUserSettings from "~/hooks/useApi/useUserSettings";
import {
  CommunicationPreferencesData,
  UserURLParams
} from "~/typing/carePortalTypes";
import {
  ScoreMeal,
  UserDetails,
  Program,
  Coach,
  SMSMessage,
  MessageThread
} from "~/typing/sidekickTypes";

type PrintUserReportProps = {
  coach?: Coach;
  energyTestData: any[];
  fitnessTestData: any[];
  getMessageSenderName: (message: any) => string;
  language: string;
  leftDate: string;
  messages: MessageThread[];
  communicationPreferences?: CommunicationPreferencesData;
  performanceData: any[];
  preview: boolean;
  sleepTestData: any[];
  slotDays: any[];
  stressTestData: any[];
  surveysData: any;
  timeZoneOffset: number;
  useFoodJournal: boolean;
  useInformation: boolean;
  useCommunicationPreferences: boolean;
  useMessages: boolean;
  useMetric: boolean;
  useNotes: boolean;
  usePRO: boolean;
  usePerformance: boolean;
  userDetails: UserDetails;
  smsMessages: SMSMessage[];
  program?: Program;
};

const PrintUserReport = forwardRef<HTMLDivElement, PrintUserReportProps>(
  (
    {
      preview,
      userDetails,
      language,
      coach,
      program,
      timeZoneOffset,
      useFoodJournal,
      useCommunicationPreferences,
      useInformation,
      usePRO,
      usePerformance,
      useNotes,
      useMessages,
      communicationPreferences,
      leftDate,
      messages,
      fitnessTestData,
      sleepTestData,
      energyTestData,
      stressTestData,
      performanceData,
      useMetric,
      surveysData,
      slotDays,
      getMessageSenderName,
      smsMessages
    }: PrintUserReportProps,
    ref
  ) => {
    const { t } = useTranslation();

    const {
      program_id = "",
      user_id = "",
      locale = ""
    } = useParams<UserURLParams>();

    const {
      coaches: careTeamCoaches,
      isLoading: careTeamLoading
    } = useCoachesForUser({
      programId: program_id,
      locale,
      userId: user_id
    });

    const { settings } = useUserSettings(user_id);

    const [scoreMeals, setScoreMeals] = useState<ScoreMeal[]>([]);

    const { startDate, endDate } = useContext(ExportContext);

    const fetchScoreMealData = async () => {
      let path = `/coach/programs/${program_id}/locales/${locale}/users/${user_id}/scoremeals`;

      if (startDate) {
        path += `?startDate=${DateTime.fromJSDate(startDate)
          .minus({ days: 1 })
          .toISODate()}`;
      }
      if (endDate) {
        path += `${startDate ? "&" : "?"}endDate=${DateTime.fromJSDate(endDate)
          .plus({ days: 1 })
          .toISODate()}`;
      }
      const res = await api.get(path);
      if (res?.res?.ok) {
        const journalsData = res?.data?.scoremeals;
        setScoreMeals(journalsData);
      }
    };

    useEffect(() => {
      if (useFoodJournal) {
        fetchScoreMealData();
      }
    }, [useFoodJournal, startDate, endDate]);

    const cardData = getHealthCardData(useMetric, userDetails);

    if (careTeamLoading) return null;

    return (
      <div className={preview ? styles.preview : styles.printSection} ref={ref}>
        <div className={`${styles.page} ${preview && styles.preview}`}>
          <div className={styles.header}>
            <div className={styles.headerPages}>
              <span className={styles.reportName}>
                {t("exportPreview.userReport")}
              </span>
            </div>
            <div className={styles.logo}>
              <img src={config.isAnthem ? AnthemLogo : SidekickLogo} />
            </div>
            <div className={styles.line} />
          </div>
          <div className={styles.pageContent}>
            <div className={`${styles.pageContent} ${styles.preview}`}>
              {useInformation && (
                <>
                  <div className={styles.flexContainerColumn}>
                    <div className={styles.flexContainer}>
                      <Avatar
                        user={userDetails?.user}
                        size="sm"
                        className={styles.printAvatar}
                      />
                      <div className={styles.avatarName}>
                        {" "}
                        {userDetails?.user?.fullName}
                      </div>
                      <img
                        className={styles.country}
                        src={getCountryFlagSrc(program?.location?.name)}
                        alt={""}
                      />
                      <span className={styles.countryName}>
                        {program?.location?.name}
                      </span>
                    </div>
                    <div className={styles.flexContainer}>
                      <div
                        style={{ marginLeft: 0 }}
                        className={`${styles.avatarName}`}
                      >
                        {careTeamCoaches?.length
                          ? t("programs.careTeam")
                          : !config.isAnthem
                          ? t("exportPreview.coach")
                          : t("exportPreview.caremanager")}
                      </div>
                      {careTeamCoaches?.length ? (
                        <CareTeam
                          careTeam={
                            careTeamCoaches?.map((coach) => ({
                              coachUserId: coach.coachId,
                              specialtyId: coach.specialtyId
                            })) ?? []
                          }
                          coaches={program?.coaches ?? []}
                          inExportMode
                        />
                      ) : (
                        <>
                          <Avatar
                            user={coach}
                            size="sm"
                            className={styles.printAvatar}
                          />
                          <div className={`${styles.avatarName}`}>
                            {" "}
                            {coach?.displayName}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.flexContainer}`}>
                    <InformationPageHeader className={styles.printMvpHeader} />
                    <div className={styles.right}></div>
                  </div>
                  <div className={styles.flexContainer}>
                    <Details
                      userDetails={userDetails}
                      language={language}
                      leftDate={
                        leftDate
                          ? DateTime.fromISO(leftDate).toLocaleString()
                          : ""
                      }
                    />
                  </div>
                  <div className={styles.gridContainer}>
                    {cardData.map((card) => {
                      return (
                        <PrintUserScore
                          scoreName={card.title}
                          value={card.value}
                          unit={card.unit}
                          key={card.title}
                          date={
                            card.date
                              ? DateTime.fromISO(card.date).toLocaleString()
                              : ""
                          }
                        />
                      );
                    })}
                    <PrintUserScore
                      scoreName={t("exportPreview.fastingBloodSugar")}
                      value={
                        userDetails?.fastingBloodSugar?.value
                          ? mmolToMg(userDetails.fastingBloodSugar.value)
                          : "-"
                      }
                      unit={t("units.mgDl")}
                      date={
                        userDetails?.fastingBloodSugar?.date
                          ? DateTime.fromISO(
                              userDetails.fastingBloodSugar.date
                            ).toLocaleString()
                          : ""
                      }
                    />
                    <PrintUserScore
                      scoreName={t("exportPreview.afterMealBloodSugar")}
                      value={
                        userDetails?.mealBloodSugar?.value
                          ? mmolToMg(userDetails.mealBloodSugar.value)
                          : "-"
                      }
                      unit={t("units.mgDl")}
                      date={
                        userDetails?.mealBloodSugar?.date
                          ? DateTime.fromISO(
                              userDetails.mealBloodSugar.date
                            ).toLocaleString()
                          : ""
                      }
                    />
                  </div>
                </>
              )}
              {useCommunicationPreferences && (
                <div>
                  <div className={styles.flexContainer}>
                    <h3>{t("communicationPreferences.title")}</h3>
                  </div>
                  <div className={styles.communicationPreferences}>
                    <h4>Preferences</h4>
                    <p>
                      {buildPreferenceString(
                        false,
                        communicationPreferences,
                        settings?.find((setting) => setting.name === "timezone")
                          ?.value
                      )}
                    </p>
                    <h4>Comment</h4>
                    <p>{communicationPreferences?.comment}</p>
                  </div>
                </div>
              )}
              {useFoodJournal && (
                <>
                  <div className={styles.flexContainer}>
                    <span className={styles.header}>
                      {t("performance.journals.title")}
                    </span>
                  </div>
                  <div>
                    {scoreMeals?.map((meal) => {
                      if (isBetweenDates(meal.serverDate, startDate, endDate)) {
                        return (
                          <PrintMealCard
                            key={meal.id}
                            journal={meal}
                            timeZoneOffset={timeZoneOffset}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </>
              )}

              {useMessages && (
                <>
                  <PrintEntityList
                    entities={messages?.[0]?.messages ?? []}
                    getMessageSenderName={getMessageSenderName}
                  />

                  <PrintEntityList
                    entities={smsMessages}
                    getMessageSenderName={getMessageSenderName}
                    useSms={true}
                  />
                </>
              )}
              {useNotes && <PrintNotes />}
              {usePerformance && (
                <>
                  <div className={styles.flexContainer}>
                    <span className={styles.bigHeader}>
                      {t("exportPreview.performance")}
                    </span>
                  </div>
                  <div className={styles.flexContainer}>
                    <span className={styles.smallHeader}>
                      {t("exportPreview.missions")}
                    </span>
                  </div>
                  <div className={styles.flexContainer}>
                    <div>
                      <PrintMissions slotDays={slotDays} />
                    </div>
                  </div>

                  <div className={styles.flexContainer}>
                    <span className={styles.smallHeader}>
                      {t("exportPreview.physicalperformance")}
                    </span>
                  </div>
                  <div className={styles.flexContainer}>
                    <span className={styles.smallHeader}>
                      {t("exportPreview.activity")}
                    </span>
                  </div>

                  <StripedTable
                    data={performanceData}
                    fieldName="physicalActivityMinutes"
                    firstColumnName="Date"
                    lastColumnName="Mins"
                  />

                  <div className={styles.flexContainer}>
                    <span className={styles.smallHeader}>
                      {t("exportPreview.stepCounter")}
                    </span>
                  </div>
                  <StripedTable
                    data={performanceData}
                    fieldName="stepCount"
                    firstColumnName={t("exportPreview.date")}
                    lastColumnName={t("units.steps")}
                  />
                  {fitnessTestData && fitnessTestData.length > 0 && (
                    <>
                      <div className={styles.flexContainer}>
                        <span className={styles.smallHeader}>
                          {t("exportPreview.fitnessTest")}
                        </span>
                      </div>
                      <div className={styles.flexContainer}>
                        <StripedTable
                          data={fitnessTestData}
                          fieldName="distance"
                          middleFieldName="mins"
                          firstColumnName={t("exportPreview.date")}
                          lastColumnName={
                            useMetric ? t("units.meters") : t("units.miles")
                          }
                          middleColumnName={t("exportPreview.minutes")}
                        />
                      </div>
                    </>
                  )}

                  <div className={styles.flexContainer}>
                    <span className={styles.smallHeader}>
                      {t("exportPreview.mentalPerformance")}
                    </span>
                  </div>
                  {sleepTestData && sleepTestData.length > 0 && (
                    <>
                      <div className={styles.flexContainer}>
                        <span className={styles.smallHeader}>
                          {t("performance.mental.sleepQuality")}
                        </span>
                      </div>
                      <div className={styles.flexContainer}>
                        <StripedTable
                          data={sleepTestData}
                          fieldName="value"
                          firstColumnName={t("exportPreview.date")}
                          lastColumnName=""
                        />
                      </div>
                    </>
                  )}

                  {stressTestData && stressTestData.length > 0 && (
                    <>
                      <div className={styles.flexContainer}>
                        <span className={styles.smallHeader}>
                          {t("exportPreview.stressLevel")}
                        </span>
                      </div>
                      <div className={styles.flexContainer}>
                        <StripedTable
                          data={stressTestData}
                          fieldName="value"
                          firstColumnName={t("exportPreview.date")}
                          lastColumnName=""
                        />
                      </div>
                    </>
                  )}
                  {energyTestData && energyTestData.length > 0 && (
                    <>
                      <div className={styles.flexContainer}>
                        <span className={styles.smallHeader}>
                          {t("exportPreview.energyLevel")}
                        </span>
                      </div>
                      <div className={styles.flexContainer}>
                        <StripedTable
                          data={energyTestData}
                          fieldName="value"
                          firstColumnName={t("exportPreview.date")}
                          lastColumnName=""
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {usePRO && (
                <>
                  <div className={styles.flexContainer}>
                    <span className={styles.bigHeader}>PRO</span>
                  </div>
                  <div className={styles.flexContainer}>
                    <div>
                      {surveysData &&
                        Object.keys(surveysData).map((surveyName) => {
                          return (
                            <PrintSurvey
                              key={surveyName}
                              survey={surveysData[surveyName]}
                            />
                          );
                        })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

PrintUserReport.displayName = "PrintUserReport";

export default PrintUserReport;
