import classNames from "classnames/bind";
import { get } from "lodash";
import { useState, useRef, ReactNode, useEffect, useMemo, useId } from "react";

import checkBoxStyles from "./CustomCheckBoxSelect.module.scss";
import { Dependencies, SelectLabel } from "./CustomSelect.components";
import styles from "./CustomSelect.module.scss";
import { OptionDependencies } from "./CustomSelect.types";

import CheckboxInput from "../checkboxInput/CheckboxInput";
import SearchInput from "../searchInput/SearchInput";

import { UNDEFINED_OPTION } from "~/constants/options";
import { getStringLengthInPixels } from "~/helpers/string/stringHelpers";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { t } from "~/i18n";
import { SelectOption } from "~/typing/carePortalTypes";

const cx = classNames.bind(styles);
const checkBoxCx = classNames.bind(checkBoxStyles);

const OPTION_TEXT_PIXELS_LIMIT = 173;
const MAX_HEIGHT_REM = 18;

type CustomSelectProp = {
  onChange: (values: string[]) => void;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
  checkboxWrapperClassName?: string;
  options: SelectOption[];
  placeholder?: string;
  noOptions?: ReactNode;
  iconSrc?: string;
  selected?: string[];
  label?: string;
  includeNoValueOption?: boolean;
  dataTestId?: string;
  allowSearch?: boolean;
  displayOptionsCount?: boolean;
  error?: string;
  isReadOnly?: boolean;
  required?: boolean;
  expanded?: boolean;
  dependencies?: OptionDependencies;
  noValuesSelectedPlaceholder?: string;
};

const CustomCheckBoxSelect = ({
  onChange,
  className = "",
  wrapperClassName = "",
  checkboxWrapperClassName = "",
  disabled,
  options,
  placeholder = t("select.placeholder"),
  noOptions = t("select.noOptions", "No options"),
  iconSrc,
  selected,
  label,
  includeNoValueOption,
  dataTestId,
  allowSearch,
  displayOptionsCount,
  isReadOnly,
  required,
  error,
  expanded,
  dependencies,
  noValuesSelectedPlaceholder = t("select.noneSelected")
}: CustomSelectProp) => {
  const id = useId();
  const [searchString, setSearchString] = useState("");
  const [showError, setShowError] = useState(false);
  const [optionsMaxHeight, setOptionsMaxHeight] = useState(0);

  const filteredOptions = useMemo(() => {
    if (!allowSearch || !searchString) return options;
    return options.filter((option) =>
      option.text?.toLowerCase().includes(searchString.toLowerCase())
    );
  }, [searchString, options]);

  const [showOptions, setShowOptions] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

  // Check if no values are selected. Behave as if all values are selected if no values are selected.
  const noValuesSelected = selectedOptions.length === 0;

  useEffect(() => {
    setSelectedOptions(
      selected?.map((select) => ({ value: select ?? "", text: "" })) ?? []
    );
  }, [selected]);

  useEffect(() => setShowOptions(expanded ?? false), [expanded]);

  useEffect(() => {
    setSelectedOptions((prev) =>
      prev.filter((option) => {
        if (includeNoValueOption && option.value === UNDEFINED_OPTION) {
          return true;
        }
        return options.some((o) => o.value === option.value);
      })
    );
  }, [options, selected?.length]);

  useEffect(() => {
    if (!showOptions) return;
    //Scroll to id options
    const options = document.getElementById(id);

    if (!options) return;

    options.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [showOptions]);

  useEffect(() => {
    if (!showOptions && !expanded) return;
    const options = document.getElementById(id);
    const optionsWrapper = document.getElementById(`wrapper-${id}`);

    const optionsWrapperHeight = optionsWrapper?.getBoundingClientRect().height;

    if (expanded && optionsWrapperHeight) {
      setOptionsMaxHeight(optionsWrapperHeight / 16 - 2);
    } else {
      const distanceToBottomInRem =
        window.innerHeight - (options?.getBoundingClientRect().top ?? 0) / 16;

      setOptionsMaxHeight(
        distanceToBottomInRem - MAX_HEIGHT_REM > 0
          ? MAX_HEIGHT_REM
          : distanceToBottomInRem - 2
      );
    }

    // calculate distance from top of options to bottom of page to get max height
  }, [expanded, showOptions]);

  const listRef = useRef(null);
  useOnClickOutside(listRef, () => {
    if (showOptions && !expanded) {
      setShowOptions(false);
    }
  });

  const handleOptionClick = (selectedOption: SelectOption) => {
    const copiedList = [...selectedOptions];
    const index = copiedList.findIndex(
      (option) => option.value === selectedOption.value
    );

    if (index < 0) {
      copiedList.push(selectedOption);
    } else {
      copiedList.splice(index, 1);
    }

    setSelectedOptions(copiedList);
    onChange(copiedList.map((option) => option.value));
  };

  const clearAll = () => {
    setSelectedOptions([]);
    onChange([]);
  };

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  const getSelectPlaceholder = () => {
    if (allValuesSelected) {
      return t("select.allSelected");
    }
    if (selectedOptions.length === 0) {
      return noValuesSelectedPlaceholder;
    }

    return selectedOptions.length > 0
      ? t("select.selected", { count: selectedOptions.length })
      : placeholder;
  };

  const renderedOptions = (includeNoValueOption
    ? [
        {
          text: t("select.noValueDefined"),
          value: UNDEFINED_OPTION
        } as SelectOption
      ]
    : []
  ).concat(filteredOptions);

  const allValuesSelected = renderedOptions.every((option) =>
    selectedOptions.some((filtered) => filtered.value === option.value)
  );

  const selectAll = () => {
    const newSelectedOptions = renderedOptions.filter(
      (option) =>
        !selectedOptions.some((selected) => selected.value === option.value)
    );

    const combinedOptions = [...selectedOptions, ...newSelectedOptions];

    setSelectedOptions(combinedOptions);
    onChange(combinedOptions.map((option) => option.value));
  };

  return (
    <div className={wrapperClassName} id={`wrapper-${id}`}>
      <SelectLabel
        label={label}
        required={required}
        options={displayOptionsCount ? filteredOptions : undefined}
      />
      <div
        data-testid={dataTestId}
        className={`${checkBoxStyles.wrapper} ${
          checkboxWrapperClassName ?? ""
        }`}
        ref={listRef}
      >
        {!expanded && (
          <button
            onClick={() => setShowOptions(!showOptions)}
            className={cx({
              select: true,
              selectButton: true,
              disabled: disabled,
              active: showOptions,
              hasError: !!error && showError,
              [className]: className !== undefined
            })}
            tabIndex={disabled ? undefined : 0}
            type="button"
          >
            {iconSrc && (
              <img src={iconSrc} alt="select icon" className={styles.icon} />
            )}
            <span>{getSelectPlaceholder()}</span>
          </button>
        )}
        {showOptions && (
          <ul
            id={id}
            data-testid="custom-checkbox-select-options"
            tabIndex={-1}
            className={checkBoxCx({
              options: !expanded,
              expandedOptions: expanded
            })}
            style={{
              maxHeight: `${optionsMaxHeight}rem`
            }}
          >
            {!isReadOnly && (
              <li
                className={checkBoxCx({
                  toggle: true
                })}
              >
                <button
                  tabIndex={0}
                  onClick={selectAll}
                  disabled={
                    (allValuesSelected && !noValuesSelected) || isReadOnly
                  }
                >
                  Select all
                </button>
                <button
                  tabIndex={0}
                  onClick={clearAll}
                  disabled={selectedOptions.length === 0 || isReadOnly}
                >
                  Clear all
                </button>
              </li>
            )}
            {allowSearch && (
              <li>
                <SearchInput
                  value={searchString}
                  className={styles.search}
                  placeholder="Search"
                  onChange={setSearchString}
                />
              </li>
            )}
            {dependencies && <Dependencies dependencies={dependencies} />}
            <span
              className={checkBoxStyles.scrollable}
              style={{
                maxHeight: `${optionsMaxHeight - 6}rem`
              }}
            >
              {renderedOptions.map((option, index) => {
                return (
                  <li
                    key={`list-item-${index}-${option.value} `}
                    className={checkBoxCx({
                      option: true,
                      carousel:
                        getStringLengthInPixels(option?.text ?? "") >
                          OPTION_TEXT_PIXELS_LIMIT && !expanded,
                      readOnly: isReadOnly
                    })}
                  >
                    <CheckboxInput
                      label={
                        option.renderOption
                          ? option.renderOption()
                          : option.text
                      }
                      checked={selectedOptions.some(
                        (o) => o.value == option.value
                      )}
                      onChange={() => handleOptionClick(option)}
                      disabled={isReadOnly}
                    />
                  </li>
                );
              })}
            </span>
            {options.length === 0 && !includeNoValueOption && (
              <li>{noOptions}</li>
            )}
          </ul>
        )}
        {error && showError && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

export default CustomCheckBoxSelect;
