import { DateTime } from "luxon";

import api from "~/api/api";
import { UserReadmission, UserWeekPoints } from "~/typing/sidekickTypes";

export const stopUserProgram = async ({
  programId,
  locale,
  userId
}: {
  programId: string;
  locale: string;
  userId: string;
}) => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/discharge`;
    const response = await api.post(path, {});
    return response;
  } catch (err) {
    console.error("Error", err);
    return null;
  }
};

export const checkForUserListChanges = async ({
  programId,
  locale,
  lastUpdate
}: {
  programId: string;
  locale: string;
  lastUpdate: DateTime;
}): Promise<boolean> => {
  try {
    const path =
      `/coach/programs/${programId}/locales/${locale}/users/changed` +
      `?oldestDate=${lastUpdate.toISO()}`;
    const response = await api.get(path);

    return response?.data?.hasChanged ?? false;
  } catch (err) {
    console.error("Error", err);
    return false;
  }
};

export const getLastWeekUserPoints = async ({
  programId,
  locale,
  userId
}: {
  programId: string;
  locale: string;
  userId: string;
}): Promise<UserWeekPoints | undefined> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/lastWeeksPoints`;

    const response = await api.get(path);

    return response?.data;
  } catch (err) {
    return undefined;
  }
};

export const restartMember = async ({
  programId,
  locale,
  userId,
  externalUserTypeId,
  externalUserId
}: {
  programId: string;
  locale: string;
  userId: string;
  externalUserTypeId?: string;
  externalUserId?: string;
}) => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/restart`;
    const response = await api.post(path, {
      externalUserTypeId,
      externalUserId
    });

    return response;
  } catch (err) {
    return null;
  }
};

export const getUserReAdmissions = async ({
  programId,
  locale,
  userProgramCatalogItemIds
}: {
  programId: string;
  locale: string;
  userProgramCatalogItemIds: number[];
}): Promise<UserReadmission[] | undefined> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/userReadmissions`;
    const response = await api.post(path, {
      userProgramCatalogItemIds
    });

    return response?.data?.items;
  } catch (err) {
    return undefined;
  }
};
