import styles from "./NarrowLayout.module.scss";

import NavHeaderNextStep from "../nav-header/nav-header-next-step/NavHeaderNextStep";

import NavHeader from "~/components/nav-header/NavHeader";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import useUser from "~/hooks/useUser";

type NarrowLayoutProps = {
  children: any;
  title?: string;
  titleImg?: string;
};

const NarrowLayout = ({ children, title, titleImg }: NarrowLayoutProps) => {
  const { user } = useUser();

  const { isEnabled: nextStepEnabled } = useFeatureToggle({
    featureName: SegmentationKey.CoachCentricView,
    userId: user?.userId
  });

  return (
    <>
      {/* Check if NextStep is enabled, if so change out NavHeader */}
      {!nextStepEnabled ? <NavHeader /> : <NavHeaderNextStep />}
      <div className={styles.NarrowLayout}>
        {title && (
          <h1 className={styles.heading}>
            {titleImg && (
              <img
                src={titleImg}
                alt="Decorative logo"
                height="24"
                width="24"
              />
            )}
            {title}
          </h1>
        )}
        <div className={styles.wrapper}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default NarrowLayout;
