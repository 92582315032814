import api from "../api";

import { CareTeamMember, UserProgramCoach } from "~/typing/sidekickTypes";

export const getCoachesForUser = async ({
  userId,
  programId,
  locale
}: {
  userId: string;
  programId: string;
  locale: string;
}) => {
  try {
    const res = await api.get<{ items: UserProgramCoach[] }>(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/coaches`
    );

    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const getCoachesForProgram = async ({
  programId,
  locale
}: {
  programId: string;
  locale: string;
}) => {
  try {
    const res = await api.get<{
      items: CareTeamMember[];
    }>(`/coach/programs/${programId}/locales/${locale}/coaches`);

    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const assignSpecialtyCoachToUser = async ({
  userId,
  programId,
  locale,
  specialtyId,
  coachId
}: {
  userId: string;
  programId: string;
  locale: string;
  specialtyId: string;
  coachId: string;
}) => {
  const res = await api.post<UserProgramCoach>(
    `/coach/programs/${programId}/locales/${locale}/users/${userId}/coaches/`,
    {
      coachId,
      specialtyId
    }
  );

  if (!res?.res.ok) throw new Error("Error assigning coach to user");

  return res?.data;
};

export const assignCMToUser = async ({
  userId,
  programId,
  locale,
  coachId
}: {
  userId: string;
  programId: string;
  locale: string;
  coachId: string;
}) => {
  try {
    const res = await api.post(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/assigntocoach`,
      { coachUserId: coachId }
    );

    return res;
  } catch (err) {
    throw err;
  }
};

export const autoAssignCoachesToUser = async ({
  userId,
  programId,
  locale,
  specialtyId
}: {
  userId: string;
  programId: string;
  locale: string;
  specialtyId: string;
}) => {
  const res = await api.post(
    `/coach/programs/${programId}/locales/${locale}/users/${userId}/coaches/autoassign`,
    { specialtyId }
  );

  if (!res?.res.ok) throw new Error("Error auto assigning coach to user");

  return res;
};

export const removeCoachFromUser = async ({
  userId,
  programId,
  locale,
  coachId,
  specialtyId
}: {
  userId: string;
  programId: string;
  locale: string;
  coachId: string;
  specialtyId: string;
}) => {
  try {
    const res = await api.delete(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/coaches/${coachId}?specialtyId=${specialtyId}`
    );
    return res;
  } catch (err) {
    throw err;
  }
};
