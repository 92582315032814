import { DateTime } from "luxon";
import { useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input/input";

import styles from "./OrderScaleModal.module.scss";

import api from "~/api/api";
import CustomPhoneInput from "~/components/customPhoneInput/CustomPhoneInput";
import Input from "~/components/input/Input";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import Modal from "~/components/modal/Modal";
import CustomSelect from "~/components/select/CustomSelect";
import states from "~/constants/states";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

type OrderDeviceProps = {
  user: any;
  onClose: () => void;
  externalPhoneNumber?: string;
  setScaleOrderStatus: (status: string) => void;
};

const OrderDevice = ({
  user,
  onClose,
  externalPhoneNumber,
  setScaleOrderStatus
}: OrderDeviceProps) => {
  const nameComponents = user?.fullName?.split(" ");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(nameComponents?.[0] || "");
  const [lastName, setLastName] = useState(
    nameComponents?.[nameComponents.length - 1] || ""
  );
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(externalPhoneNumber || "");

  const fieldData = [
    {
      label: "First name",
      key: "firstName",
      value: firstName,
      setValue: setFirstName,
      placeholder: "Enter first name",
      autoFocus: true,
      required: true
    },
    {
      label: "Last name",
      key: "lastName",
      value: lastName,
      setValue: setLastName,
      placeholder: "Enter last name",
      required: true
    },
    {
      label: "Address (not a PO box)",
      key: "address",
      value: address,
      setValue: setAddress,
      placeholder: "Enter address",
      required: true
    },
    {
      label: "Address line 2",
      key: "address2",
      value: address2,
      setValue: setAddress2,
      placeholder: "Enter address"
    },
    {
      label: "City",
      key: "city",
      value: city,
      setValue: setCity,
      placeholder: "Enter city",
      required: true
    },
    {
      label: "State",
      key: "state",
      value: state,
      setValue: setState,
      placeholder: "Select state",
      required: true,
      select: {
        renderOption: (option) => option.name,
        options: [...states],
        keyValue: "code"
      }
    },
    {
      label: "Postal code",
      key: "postalCode",
      value: postalCode,
      setValue: setPostalCode,
      placeholder: "Enter postal code",
      required: true
    },
    {
      label: "Email",
      key: "email",
      value: email,
      setValue: setEmail,
      placeholder: "Enter email",
      required: true,
      type: "email"
    }
  ];

  const requiredFields = fieldData.filter((field) => field.required);

  const validateForm = () => {
    setErrors({});
    const errors = {};

    requiredFields.forEach((field) => {
      if (!field.value) {
        errors[field.key] = "Must not be blank";
      }
    });

    if (!phoneNumber) {
      errors["phoneNumber"] = "Must not be blank";
    } else if (!isPossiblePhoneNumber(phoneNumber)) {
      errors["phoneNumber"] = "Invalid phone number";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const orderScale = async () => {
    setLoading(true);

    const path = `/coach/users/${user.userId}/extras/iredeem/order`;

    const payload = {
      firstName,
      lastName,
      address,
      address2,
      city,
      state,
      postalCode,
      email
    };

    const orderRes = await api.post(path, payload);

    if (orderRes?.res?.ok && orderRes.data) {
      const { decoratedStatus, orderDate } = orderRes.data;
      const orderDateString = DateTime.fromISO(orderDate).toLocaleString();
      setScaleOrderStatus(
        `Scale ${decoratedStatus} (order id: pending): ${orderDateString}`
      );
      displaySuccessToast({ message: t("informationPage.scale.successOrder") });
    } else {
      displayErrorToast({
        message:
          orderRes?.body?.error?.message?.split("|")?.[1] ||
          t("errors.genericReload")
      });
      setScaleOrderStatus("error");
    }
    setLoading(false);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      orderScale();
    }
  };
  return (
    <Modal
      title="Order BodyTrace Scale"
      onClose={onClose}
      className={styles.orderScale}
    >
      {loading && <LoadingSpinner />}
      <form
        onSubmit={handleSubmit}
        className={`${styles.form} form--block ${loading ? styles.hidden : ""}`}
      >
        <div className={styles.fields}>
          {fieldData.map(
            ({
              key,
              label,
              value,
              setValue,
              autoFocus,
              placeholder,
              required,
              select
            }) => {
              const onSelectChange = (e) => setValue(e.target.value);
              const onInputChange = (value: string) => setValue(value);

              return (
                <div className={styles.inputContainer} key={key}>
                  {select ? (
                    <CustomSelect
                      value={value}
                      valueKey={select.keyValue}
                      onChange={onSelectChange}
                      renderOption={select.renderOption}
                      options={select.options}
                      placeholder={placeholder}
                      label={label}
                    />
                  ) : (
                    <Input
                      label={label}
                      required={required}
                      placeHolder={placeholder}
                      initialValue={value}
                      onChange={onInputChange}
                      autoFocus={autoFocus}
                    />
                  )}

                  {<p className={styles.error}>{errors[key]}</p>}
                </div>
              );
            }
          )}
          <div className={styles.inputContainer}>
            <label className="label label--block">Phone number</label>
            <div className="inputContainer">
              <CustomPhoneInput
                value={phoneNumber}
                onChange={(val) => setPhoneNumber(val)}
                defaultCountry="US"
                className={errors["phoneNumber"] ? "error" : ""}
                useCountrySelect={false}
              />
            </div>
            <p className={styles.error}>{errors["phoneNumber"]}</p>
          </div>
        </div>
        <div className={styles.actions}>
          <button type="submit" className="btn-primary" disabled={loading}>
            Order
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default OrderDevice;
