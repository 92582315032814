import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import authApi from "~/api/authApi";
import { fetchUser } from "~/state/user/userSlice";

export const login = createAsyncThunk(
  "auth/login",
  async (
    {
      email,
      password,
      cb
    }: { email: string; password: string; cb: () => void },
    { dispatch }
  ) => {
    const res = await dispatch(loginRequest({ email, password, cb }));
    if (res?.payload?.auth_account_id) {
      dispatch(fetchUser());
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (cb: () => void) => {
    authApi.logout();

    if (cb) {
      cb();
    }

    return true;
  }
);

export const loginRequest = createAsyncThunk(
  "auth/loginRequest",
  async ({
    email,
    password,
    cb
  }: {
    email: string;
    password: string;
    cb: () => void;
  }) => {
    const response = await authApi.login(email, password);

    if (cb) cb();

    return response;
  }
);

export type AuthState = {
  loginstatus: string;
  loginerror?: string;
  logoutstatus: string;
  logouterror?: string;
};

const initialState: AuthState = {
  loginstatus: "",
  logoutstatus: ""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state) => {
      state.loginstatus = "loading";
      state.loginerror = undefined;
    });
    builder.addCase(loginRequest.fulfilled, (state) => {
      state.loginstatus = "success";
      state.loginerror = undefined;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.loginstatus = "failed";
      state.loginerror = action.error.message;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginstatus = "failed";
      state.loginerror = action.error.message;
    });
    builder.addCase(logout.pending, (state) => {
      state.logoutstatus = "loading";
      state.logouterror = undefined;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.logoutstatus = "success";
      state.logouterror = undefined;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.logoutstatus = "failed";
      state.logouterror = action.error.message;
    });
  }
});

const authReducer = authSlice.reducer;

export { authReducer };
