import classNames from "classnames/bind";
import { ReactNode } from "react";

import styles from "./Button.module.scss";

import PulseLoader from "../pulseLoader/PulseLoader";

const cx = classNames.bind(styles);

type ButtonProps = {
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  type?: "submit" | "button";
  inverted?: boolean;
  size?: "sm" | "lg";
  className?: string;
  dataTestId?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  tabIndex?: number;
};

const Button = ({
  isLoading,
  disabled,
  onClick,
  children,
  type = "submit",
  inverted,
  size = "sm",
  className,
  dataTestId,
  onKeyDown,
  tabIndex
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled || isLoading}
      className={cx({
        button: true,
        "btn-primary": !inverted,
        "btn-secondary": inverted,
        "btn-sm": size === "sm",
        loading: isLoading,
        [className ?? ""]: className !== undefined
      })}
      type={type}
      data-testid={dataTestId}
      tabIndex={tabIndex ?? 0}
    >
      {isLoading ? (
        <>
          &nbsp;
          <PulseLoader inverted={inverted} />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
