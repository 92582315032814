import { createBrowserHistory } from "history";

import config from "~/config";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

interface ApiRequestParams {
  path: string;
  method: "GET";
  payload?: any;
  returnError?: boolean;
}

async function apiRequest({
  path,
  method,
  payload,
  returnError = false
}: ApiRequestParams) {
  const reqConfig: RequestInit = {
    method: method,
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-SK-PORTAL": `care-${config.platform}`,
      Pragma: "no-cache",
      "Cache-Control": "private, max-age=172800"
    }),
    credentials: "include"
  };

  if (payload) {
    reqConfig.body = JSON.stringify(payload);
  }

  try {
    const res = await fetch(path, reqConfig);

    // body sometimes empty on DELETE responses
    let body;

    if (!res.ok) {
      if (res.status === 401) {
        const history = createBrowserHistory();
        const routesToIgnore = ["/", "/login", "/resetpassword", "/signup"];

        if (!routesToIgnore.includes(history.location.pathname)) {
          history.push("/login?sessionExpired=true", {
            from: history.location
          });
          history.go(0);
        }
      } else if (res.status === 403) {
        const history = createBrowserHistory();
        if (
          history.location.pathname !== "/login" &&
          !(window as any).Cypress
        ) {
          displayErrorToast({
            message: t("login.errors.missingPrivileges"),
            id: "attachment-api-missing-privilege"
          });
        }
        console.error(
          `Missing privileges ${res.status}: ${body?.error?.message}`,
          { body: body }
        );

        return { res, body };
      } else {
        console.error(
          `Request to backend returned an error ${res.status}: ${body?.error?.message}`,
          { body: body }
        );
        return { res, body };
      }

      if (returnError) {
        return { res: res, body: body };
      } else {
        return null;
      }
    }

    //Read the stream that comes from the attachment servlet
    const reader = res?.body?.getReader();
    const rs = new ReadableStream({
      start(controller) {
        return pump();
        function pump() {
          return reader?.read().then(({ done, value }) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }
            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            return pump();
          });
        }
      }
    });

    //Create the blob url for the attachment
    const response = new Response(rs);
    const blob = await response.blob();
    const attachmentURL = URL.createObjectURL(blob);

    return { res, data: attachmentURL };
  } catch (err) {
    console.error("Request to backend failed", {
      err: err
    });

    return null;
  }
}

export default {
  get: async (path: string) => {
    return apiRequest({ path, method: "GET" });
  }
};
