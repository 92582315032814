import { useEffect, useState } from "react";

import styles from "./RemoveFromProgramModal.module.scss";

import ErrorScreen from "../error/ErrorScreen";
import ExpandedCheckboxSelect from "../expandedCheckBoxSelect/ExpandedCheckboxSelect";
import PreviewForm from "../previewForm/PreviewForm";
import SuccessScreen from "../success/SuccessScreen";

import Avatar from "~/components/avatar/Avatar";
import ModalWithFooterActions from "~/components/modal/ModalWithFooterActions";
import {
  useCareManagersAndInvites,
  useRemoveCareManager
} from "~/hooks/graphql/useCareManagersAndInvites";
import usePrograms from "~/hooks/useApi/usePrograms";
import { t } from "~/i18n";
import { PreviewFormData } from "~/typing/carePortalTypes";
import { CareManagerWithPcIs } from "~/typing/graphql/types";

type RemoveFromProgramModalProps = {
  onClose: () => void;
  selectedCareManager: CareManagerWithPcIs;
  onSuccess: () => void;
};

enum RemoveFromProgramStep {
  SelectPrograms = "SelectPrograms",
  SelectCareManager = "SelectCareManager",
  Preview = "Preview",
  Success = "Success",
  Error = "Error"
}

const RemoveFromProgramModal = ({
  onClose,
  onSuccess,
  selectedCareManager
}: RemoveFromProgramModalProps) => {
  const [step, setStep] = useState<RemoveFromProgramStep>(
    RemoveFromProgramStep.SelectPrograms
  );
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);

  const [currentProgramIndex, setCurrentProgramIndex] = useState(0);

  const [
    selectedCareManagersForProgram,
    setSelectedCareManagersForProgram
  ] = useState<Record<string, string[]>>({}); // program id -> care manager id

  const { programs, isLoading: programsIsLoading } = usePrograms();

  const getUserCountForProgram = (
    careManager: CareManagerWithPcIs,
    programId: string,
    locale: string
  ) => {
    return (
      careManager?.coachUserStatistics?.find(
        (cus) =>
          cus?.programCatalogItemId.toString() === programId &&
          cus?.locale === locale
      )?.totalUserCount || 0
    );
  };

  const { coaches } = useCareManagersAndInvites();

  const getCoachesOptionsForProgram = () => {
    //Don't allow the selected care manager to be selected
    //Only show care managers that have the program in their PCIs
    const careManagersForCurrentProgram = coaches
      .filter(
        (coach) =>
          coach.careManager?.careManagerId !==
          selectedCareManager.careManager?.careManagerId
      )
      .filter((coach) =>
        coach.pcis?.some((pci) => {
          const { programId, locale } = getProgramIdAndLocaleFromKey(
            selectedPrograms[currentProgramIndex]
          );
          return (
            pci?.programCatalogItemId.toString() === programId &&
            pci?.locale === locale
          );
        })
      );

    return careManagersForCurrentProgram
      .filter((coach) => coach?.careManager?.careManagerExists) //Filter out invites
      .map((coach) => {
        const careManager = coach?.careManager ?? undefined;

        const { programId, locale } = getProgramIdAndLocaleFromKey(
          selectedPrograms[currentProgramIndex]
        );
        const userCount = getUserCountForProgram(coach, programId, locale);

        return {
          text: careManager?.name ?? careManager?.email ?? "",
          value: careManager?.careManagerId ?? "",
          renderOption: () => (
            <Avatar
              user={{
                fullName: `${careManager?.name} (${userCount} users)` ?? "",
                imageHref: careManager?.imageHref
              }}
              displayTitle
            />
          )
        };
      });
  };

  const getProgramOptions = () => {
    return (
      selectedCareManager?.pcis
        ?.map((pci) =>
          programs.find(
            (program) =>
              program.programCatalogItemId ===
                pci?.programCatalogItemId.toString() &&
              program.locale === pci?.locale
          )
        )
        .map((program) => {
          const programName = program?.name;
          const localeString = ` • ${program?.locale.toUpperCase()}`;
          const userCount = getUserCountForProgram(
            selectedCareManager,
            program?.programCatalogItemId ?? "",
            program?.locale ?? ""
          );
          const userCountString = `• ${userCount} users`;
          return {
            text: `${programName} ${localeString} ${userCountString}`,
            value: getProgramLocaleKey(
              program?.programCatalogItemId ?? "",
              program?.locale ?? ""
            )
          };
        }) ?? []
    );
  };

  //As the program id isn't unique, we need to create a composite key with the program id and locale
  //We need a function to create the key and another to split it into an object with the program id and locale
  const getProgramLocaleKey = (programId: string, locale: string) =>
    `${programId}_${locale}`;
  const getProgramIdAndLocaleFromKey = (key: string) => {
    const [programId, locale] = key.split("_");
    return { programId, locale };
  };

  const traverseProgramIndex = (indexChange: 1 | -1) => {
    let newIndex = currentProgramIndex + indexChange;

    while (newIndex >= 0 && newIndex < selectedPrograms.length) {
      if (getAssignedUsersToEachProgram()[newIndex] > 0) {
        setStep(RemoveFromProgramStep.SelectCareManager);
        setCurrentProgramIndex(newIndex);
        return;
      }
      newIndex += indexChange;
    }

    if (newIndex < 0) {
      setStep(RemoveFromProgramStep.SelectPrograms);
    } else if (newIndex >= selectedPrograms.length) {
      setStep(RemoveFromProgramStep.Preview);
    }
  };

  const handleNextClick = () => {
    if (step === RemoveFromProgramStep.SelectPrograms) {
      if (getAssignedUsersToEachProgram()[currentProgramIndex] > 0) {
        setStep(RemoveFromProgramStep.SelectCareManager);
      } else {
        traverseProgramIndex(1);
      }
    } else if (step === RemoveFromProgramStep.SelectCareManager) {
      traverseProgramIndex(1);
    } else if (step === RemoveFromProgramStep.Preview) {
      handleRemoveCareManager();
    } else if (
      step === RemoveFromProgramStep.Success ||
      step === RemoveFromProgramStep.Error
    ) {
      onClose();
    }
  };

  const handleBackClick = () => {
    if (step === RemoveFromProgramStep.SelectCareManager) {
      traverseProgramIndex(-1);
    } else if (step === RemoveFromProgramStep.Preview) {
      if (getAssignedUsersToEachProgram()[currentProgramIndex] > 0) {
        setStep(RemoveFromProgramStep.SelectCareManager);
      } else {
        const backIndex = -1;

        if (backIndex >= 0 && backIndex < selectedPrograms.length) {
          traverseProgramIndex(backIndex);
        } else {
          setStep(RemoveFromProgramStep.SelectPrograms);
        }
      }
    } else {
      onClose();
    }
  };

  const getCurrentProgram = () => {
    return programs.find(
      (program) =>
        program.programCatalogItemId ===
        getProgramIdAndLocaleFromKey(selectedPrograms[currentProgramIndex])
          .programId
    );
  };

  const getAssignedUsersToEachProgram = () => {
    return selectedPrograms.map((programKey) => {
      const { programId, locale } = getProgramIdAndLocaleFromKey(programKey);
      return getUserCountForProgram(selectedCareManager, programId, locale);
    });
  };
  const getModalTitle = () => {
    if (step === RemoveFromProgramStep.SelectPrograms) {
      return t("accessManagement.removeFromProgramModal.removeFromProgram");
    }
    if (step === RemoveFromProgramStep.SelectCareManager) {
      return t("accessManagement.removeFromProgramModal.reassignUsersIn", {
        programName: getCurrentProgram()?.name
      });
    }
    if (step === RemoveFromProgramStep.Preview) {
      return t("accessManagement.removeFromProgramModal.removeAndReassign");
    }
    return "";
  };

  const getPreviewFormData = (): PreviewFormData[] => {
    const previewData: PreviewFormData[] = [];

    for (const programKey of selectedPrograms) {
      const { programId, locale } = getProgramIdAndLocaleFromKey(programKey);

      //Get user count for selected CM
      const userCountForSelectedCM = getUserCountForProgram(
        selectedCareManager,
        programId,
        locale
      );

      const program = programs.find(
        (program) =>
          program.programCatalogItemId === programId &&
          program.locale === locale
      );

      const careManagersForProgram = coaches.filter((coach) =>
        selectedCareManagersForProgram[programKey]?.includes(
          coach.careManager?.careManagerId ?? ""
        )
      );

      previewData.push({
        values: [
          {
            renderOption: () =>
              `${program?.name} • ${program?.locale.toUpperCase()}`
          },
          {
            renderOption: () => `${userCountForSelectedCM} users`
          }
        ]
      });

      previewData.push({
        withBorder: true,
        heading: t(
          "accessManagement.removeFromProgramModal.reassignedToFollowing",
          {
            careManagersCount: careManagersForProgram.length
          }
        ),
        values: careManagersForProgram.map(({ careManager }) => ({
          renderOption: () => (
            <Avatar
              displayTitle
              user={{
                fullName: careManager?.name,
                imageHref: careManager?.imageHref
              }}
            />
          )
        }))
      });
    }

    return previewData;
  };

  const isNextButtonDisabled = () => {
    if (step === RemoveFromProgramStep.SelectPrograms) {
      return selectedPrograms.length === 0;
    }
    if (step === RemoveFromProgramStep.SelectCareManager) {
      return (
        !selectedCareManagersForProgram[
          selectedPrograms[currentProgramIndex]
        ] ||
        selectedCareManagersForProgram[selectedPrograms[currentProgramIndex]]
          .length === 0
      );
    }
    if (removeCareManagerIsPending) {
      return true;
    }
    return false;
  };

  //graphql mutation to remove care manager from program and assign users to new cms
  const {
    removeCareManager,
    isPending: removeCareManagerIsPending,
    isSuccess: removeCareManagerSuccess,
    isError: removeCareManagerError
  } = useRemoveCareManager();

  const handleRemoveCareManager = () => {
    //Remove care manager for each selected program.
    //Build a CareManagerReassignmentDtoInput for each program
    const reassignments = selectedPrograms.map((programLocaleKey) => {
      const { programId, locale } = getProgramIdAndLocaleFromKey(
        programLocaleKey
      );
      const cmsForProgram =
        selectedCareManagersForProgram[programLocaleKey] ?? [];

      return {
        locale,
        programCatalogItemId: programId,
        receivingCareManagerIds: cmsForProgram
      };
    });

    removeCareManager({
      removeCareManagerId: selectedCareManager.careManager?.careManagerId,
      reassignments
    });
  };

  useEffect(() => {
    if (removeCareManagerSuccess) {
      setStep(RemoveFromProgramStep.Success);
      onSuccess();
    } else if (removeCareManagerError) {
      setStep(RemoveFromProgramStep.Error);
    }
  }, [removeCareManagerSuccess, removeCareManagerError]);

  return (
    <ModalWithFooterActions
      className={styles.modal}
      title={getModalTitle()}
      contentClass={styles.modalContent}
      onClose={onClose}
      actions={[
        {
          label:
            step === RemoveFromProgramStep.SelectPrograms
              ? t("general.cancel")
              : t("general.back"),
          onClick: handleBackClick,
          hidden:
            step === RemoveFromProgramStep.Success ||
            step === RemoveFromProgramStep.Error,
          disabled: removeCareManagerIsPending
        },
        {
          label:
            step === RemoveFromProgramStep.Success ||
            step === RemoveFromProgramStep.Error
              ? t("general.done")
              : t("general.next"),
          onClick: handleNextClick,
          disabled: isNextButtonDisabled()
        }
      ]}
    >
      {![
        RemoveFromProgramStep.Preview,
        RemoveFromProgramStep.Success,
        RemoveFromProgramStep.Error
      ].includes(step) && (
        <div className={styles.avatarWrapper}>
          <p>{t("roles.careManager")}</p>
          <Avatar
            user={{
              fullName: selectedCareManager.careManager?.name,
              imageHref: selectedCareManager.careManager?.imageHref
            }}
            displayTitle
          />
        </div>
      )}
      {step === RemoveFromProgramStep.SelectPrograms && (
        <>
          <ExpandedCheckboxSelect
            description={t(
              "accessManagement.removeFromProgramModal.selectProgramsToRemoveFrom",
              {
                careManager: selectedCareManager.careManager?.name
              }
            )}
            options={getProgramOptions()}
            onChange={setSelectedPrograms}
            selected={selectedPrograms}
            loading={programsIsLoading}
            className={styles.checkboxList}
          />
        </>
      )}
      {step === RemoveFromProgramStep.SelectCareManager && (
        <ExpandedCheckboxSelect
          description={t(
            "accessManagement.removeFromProgramModal.selectCareManagersToAcceptUsers",
            {
              careManager: selectedCareManager.careManager?.name,
              userCount: getUserCountForProgram(
                selectedCareManager,
                getProgramIdAndLocaleFromKey(
                  selectedPrograms[currentProgramIndex]
                ).programId,
                getProgramIdAndLocaleFromKey(
                  selectedPrograms[currentProgramIndex]
                ).locale
              )
            }
          )}
          options={getCoachesOptionsForProgram()}
          onChange={(selected) =>
            setSelectedCareManagersForProgram({
              ...selectedCareManagersForProgram,
              [selectedPrograms[currentProgramIndex]]: selected
            })
          }
          selected={
            selectedCareManagersForProgram[
              selectedPrograms[currentProgramIndex]
            ]
          }
        />
      )}
      {step === RemoveFromProgramStep.Preview && (
        <PreviewForm
          selectedCareManager={selectedCareManager}
          data={getPreviewFormData()}
        />
      )}
      {step === RemoveFromProgramStep.Error && (
        <ErrorScreen
          text={t("accessManagement.errors.errorReassigningUsers")}
        />
      )}
      {step === RemoveFromProgramStep.Success && (
        <SuccessScreen text="Successfully removed from program" />
      )}
    </ModalWithFooterActions>
  );
};

export default RemoveFromProgramModal;
