import config from "~/config";

interface ApiRequestParams {
  path: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  payload?: any;
  returnError?: boolean;
}

async function apiRequest({ path, method, payload }: ApiRequestParams) {
  const reqConfig: RequestInit = {
    method: method,
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-SK-PORTAL": `care-${config.platform}`,
      Pragma: "no-cache",
      "Cache-Control": "stale-while-revalidate=86400"
    }),
    credentials: "include"
  };

  if (payload) {
    reqConfig.body = JSON.stringify(payload);
  }

  try {
    const res = await fetch(config.apiBaseUrl + path, reqConfig);
    // body sometimes empty on DELETE responses
    let body;
    try {
      body = await res.json();
    } catch {
      body = {};
    }
    return { res, data: body };
  } catch (err) {
    console.error("Request to backend failed", {
      err: err
    });

    return null;
  }
}

export default {
  get: async (path: string) => {
    return apiRequest({ path, method: "GET" });
  },

  post: async (path: string, payload: any, returnError?: boolean) => {
    return apiRequest({ path, method: "POST", payload, returnError });
  },

  put: async (path: string, payload: any) => {
    return apiRequest({ path, method: "PUT", payload });
  },

  delete: async (path: string, returnError?: boolean) => {
    return apiRequest({ path, method: "DELETE", payload: null, returnError });
  }
};
