import { useEffect } from "react";
import toast, { Toaster, ToastBar, useToasterStore } from "react-hot-toast";

const Toast = () => {
  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 3;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);
  return (
    <Toaster
      containerStyle={{
        // make the toasts appear above everything
        zIndex: 9999999999
      }}
    >
      {(t) => (
        <ToastBar
          style={{
            maxWidth: "100vw"
          }}
          data-testid="toast"
          toast={t}
        >
          {({ icon, message }) => (
            <div
              data-testid="toast"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: 8,
                maxWidth: "700px"
              }}
            >
              {icon}
              {message}
              {t.type !== "loading" && (
                <button onClick={() => toast.dismiss(t.id)}>X</button>
              )}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;
