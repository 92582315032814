import classNames from "classnames/bind";
import { Link, useLocation } from "react-router-dom";

import styles from "./NavHeaderLink.module.scss";

const cx = classNames.bind(styles);

type NavHeaderLinkProps = {
  href: string;
  text: string;
  highlightOnPath?: string;
  hidden?: boolean;
};

const NavHeaderLink = ({
  href,
  text,
  highlightOnPath,
  hidden
}: NavHeaderLinkProps) => {
  const location = useLocation();

  if (hidden) return null;

  return (
    <Link
      to={href}
      className={cx({
        link: true,
        active:
          location.pathname.startsWith(href) ||
          (highlightOnPath && location.pathname.startsWith(highlightOnPath))
      })}
    >
      {text}
    </Link>
  );
};

export default NavHeaderLink;
